import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ImageUpload from "../CategoryDetails/ImageUpload/ImageUpload";
import Loader from "../../Loader/Loader";
import { createCategory } from "../../../redux/Thunks/Thunks";
import {resetState} from "../../../redux/Slice/UpdateCategory";
import "./CustomCategory.scss";
import { uploadTrainingImages } from "../../../firebase/Storage/Storage";
import { AuthContext } from "../../../firebase/Authentication/context";
import { creditCheck } from "../../../firebase/Setup/Setup";
/**
 *@component Component to edit and create category
 * @ImageUpload use to upload images and also list all images
 *
 * @param  {function}  generateHandler  function to generate images and generate alert
 * @return  {Jsx}  It returns jsx for render a edit and create category.
 *
 */

function CustomCategory({
  generateHandler,
  setSelectedCategory,
  selectedCategory,
  subCategoryClickHandle,
}) {
  const [selectedFile, setSelectedFile] = useState([]);
  const [showGenericInformation, setShowGenericInformation] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true);
  const {user, isTM, parentAccount} = useContext(AuthContext);
  const [inputValues, setInputValues] = useState({
    ...selectedCategory,
    training_type: "Other",
    refCategory: "null",
    instance_prompt:"",
    description:"",
    name:"",
    class_prompt:"",

  });
  const [uploadPromise, setUploadPromise] = useState(undefined);

  const dispatch = useDispatch();
  const { loading, success, isError, errorMessage } =
    useSelector((state) => state.category) || {};
  const allCategories = useSelector((state) => state.categoryState) || {};

  
  useEffect(() => {
    setInputValues({
      ...selectedCategory,
      training_type: "Other",
      refCategory: "null",
      instance_prompt:"",
      description:"",
      name:"",
      class_prompt:"",
    });
  }, [selectedCategory]);

 

  useEffect(()=>{
    console.log(loading)
    if(success){
      subCategoryClickHandle('available');
      dispatch(resetState())
    }
    if(isError){
      toast.error(errorMessage, {
        position: "top-center",
      });
      dispatch(resetState())
    }
  },[success, isError])

  const onChangeHandle = (e) => {
    const { value = "", name } = e.target || {};
    if (name === 'training_type') {
      if(value === 'other'){
         setShowGenericInformation(true)
      }else{
         setShowGenericInformation(false)
      }
    }
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };


  useEffect(() =>{
    if(inputValues?.training_type==="other")
    {
      if(inputValues?.instance_prompt!=="" && inputValues?.class_prompt!=="" && inputValues?.description!=="" && inputValues?.name!=="")
      {
        setIsDisabled(false);
      }else
      {
        setIsDisabled(true);
      }
    }else{
      if(inputValues?.instance_prompt!=="" && inputValues?.description!=="" && inputValues?.name!=="")
      {
        setIsDisabled(false);
      }else
      {
        setIsDisabled(true);
      }
    }
  },[inputValues])

  useEffect(()=>{
    if(uploadPromise!==undefined){
      toast.promise(uploadPromise, {
        pending: "Please wait while we upload your images...",
        success: "Images successfully uploaded!",
        error: "Your images could not be uploaded, try again in a few minutes.",
        position: "top-center"
      });
    }
  }, [uploadPromise]);

  const categoryClickHandle = async (e) => {
    e.preventDefault();
    const uid = isTM?parentAccount:user.uid;
    if (selectedFile?.length >= 10) {

      let obj={};
      const response = await creditCheck({generateCreditsNeeded:0, saveCreditsNeeded:0, searchCreditsNeeded:0, categoryCreditsNeeded:1 });
      if(response.data.status===200 && response.data.credits===true)
      {
        setUploadPromise(new Promise((resolve) =>{
          uploadTrainingImages(selectedFile, uid).then((response) =>{
            setTimeout((resolve(response), 300));
            obj = response;

            let instance_prompt = 'a '+inputValues.instance_prompt.split(' ').join('_')+'_'+(new Date().getFullYear());
            let class_prompt=""

            if(inputValues.training_type === 'man' || inputValues.training_type === 'woman') {
              class_prompt='person';
            }

            if(inputValues.training_type === 'couple') { 
              class_prompt='couple';
            }


            if(inputValues.training_type === 'other') {
              class_prompt = inputValues.class_prompt;
            }

            instance_prompt += (' ' + class_prompt);
            class_prompt = 'a '+class_prompt;
            
            const categoryObj = {
              instance_prompt: instance_prompt,
              class_prompt: class_prompt,
              base_model_id: inputValues.refCategory==="null"?"null":inputValues.refCategory,
              paths: obj.paths,
              training_type: inputValues.training_type,
              description: inputValues.description,
              name: inputValues.name,
              mainImageUrl: obj.mainUrl,
              timestamp: obj.timestamp
            };
            
            dispatch(createCategory(categoryObj));
            generateHandler("category");
          });
        }));
      }else {
        toast.warning(response.data?.message,{
          position: "top-center",
        })
      }
    
    } else {
      toast.error("Please upload at least 10 images!", {
        position: "top-center",
      });
    }
    setIsDisabled(false);
  };

  if(loading){
    return <Loader />
  }

  return (
    <div className="custom_category_container">
      <div className="wrapper">
        <div className="header">
          <div className="title">
            {selectedCategory?.name ? "Edit" : "Create"} Custom
            category
          </div>
          <div className="description">Upload reference images of a particular human/object to create a custom category that will usually let you generate highly relevant and accurate images using a prompt</div>
        </div>
        <div className="name_input">
          <div className="title">Name</div>
          <input
            type="text"
            aria-label="Name"
            id="name"
            name="name"
            value={inputValues?.name || ""}
            placeholder="Name"
            className="input"
            onChange={onChangeHandle}
          />
        </div>
        <div className="name_input">
          <div className="title">Description</div>
          <textarea
            className="form-control input"
            name="description"
            rows="4"
            value={inputValues?.description || ""}
            onChange={onChangeHandle}
            placeholder="Eg. This category is for creating pictures in style of Picasso."
          ></textarea>
        </div>
        <ImageUpload
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
        />
        <div className="name_input">
          <div className="title">Object type</div>
          <div className="description">
            Select the type of object you are training
          </div>
          <select
            className="form-select"
            aria-label="Default select example"
            name="training_type"
            onChange={onChangeHandle}
          >
            <option
              selected={inputValues?.training_type === "couple"}
              value="couple"
            >
              Couple
            </option>
            <option
              selected={inputValues?.training_type === "woman"}
              value="woman"
            >
              Woman
            </option>
            <option selected={inputValues?.training_type === "man"} value="man">
              Man
            </option>
            <option
              selected={inputValues?.training_type === "other"}
              value="other"
            >
              Other
            </option>
          </select>
        </div>
        {showGenericInformation && <div className="name_input">
          <div className="title">Generic Information</div>
          <div className="description">Write some generic information about this category</div>
          <textarea
            className="form-control input"
            name="class_prompt"
            rows="2"
            value={inputValues?.class_prompt || ""}
            placeholder="dog, spacecraft, baby..."
            onChange={onChangeHandle}
          ></textarea>
        </div>}
        <div className="name_input">
          <div className="title">Describe</div>
          <div className="description">
          Describe in 1-4 words about the reference images. For example - if you've uploaded images of a guy called simon - then write "simon 2023 man", if you've uploaded images of an object like sword - write "Swords silver"
          </div>
          <textarea
            className="form-control input"
            name="instance_prompt"
            rows="2"
            value={inputValues?.instance_prompt || ""}
            placeholder="my dog, julia and hanna, freddy mercury, me when I was a baby, futuristic spacecraft"
            onChange={onChangeHandle}
          ></textarea>
        </div>
        <div className="name_input">
          <div className="title">Base Model</div>
          <div className="description">
            We would recommend you choose Stable Diffusion for generating real world images
            and midjourney for generating cartoons.
          </div>
          <select
            className="form-select"
            aria-label="Default select example"
            name="refCategory"
            onChange={onChangeHandle}
          >
            <option value={"null"}>StableDiffusion</option>
            {
              
              allCategories?.categories?.public && allCategories.categories.public.map((category, index) => {
                return <option value={category.model}>{category.name}</option>
              })
            }
          </select>
        </div>
        <div className="generator">
          <button
            className="btn btn-default ms-2 mb-2"
            onClick={() => subCategoryClickHandle("available")}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary ms-2 mb-2 btn-yellow"
            onClick={(e) => {
              setIsDisabled(true);
              categoryClickHandle(e);
            }}
            disabled={loading ? true : isDisabled}
          >
            {selectedCategory?.name ? "Save" : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomCategory;