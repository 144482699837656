import React, { useContext, useState, useEffect } from "react";
import Passoword from "../../components/UI/TabContent/Password/Passoword";
import Profile from "../../components/UI/TabContent/Profile/Profile";
import SubAccount from "../../components/UI/TabContent/SubAccount/SubAccount";
import { AuthContext } from "../../firebase/Authentication/context";
import "./UserProfile.scss";

const UserProfile = () => {
  const {user, session, isTM, parentAccount} = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState("myProfile");
  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
    {!user?(null):(
    <>
      <div className="userProfile">
        <div className="content-wrapper">
          <div className="tabs">
            <button
              className={`tab-item ${
                activeTab === "myProfile" ? "active" : ""
              }`}
              onClick={() => onClickTabItem("myProfile")}
            >
              My Profile
            </button>
            <button
              className={`tab-item ${activeTab === "password" ? "active" : ""}`}
              onClick={() => onClickTabItem("password")}
            >
              Password
            </button>
            {!isTM?(
            <button
              className={`tab-item ${
                activeTab === "subAccount" ? "active" : ""
              }`}
              onClick={() => onClickTabItem("subAccount")}
            >
              Sub Accounts
              <span></span>
            </button>):(null)
             }
          </div>
          <div className="tab-content">
            {activeTab === "myProfile" && (
              <div className="tab-content_wrapper ms-md-4">
                <Profile />
              </div>
            )}
            {activeTab === "password" && (
              <div className="tab-content_wrapper ms-md-4">
                <Passoword />
              </div>
            )}
            
            { !isTM?(activeTab === "subAccount" && (
              <div className="tab-content_wrapper">
                <SubAccount />
              </div>
            )):(null)}
          </div>
        </div>
      </div>
    </>)
  } 
  </>
  );
};

export default UserProfile;
