import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import data from "../../TestData/Categories";
import CategoryViewModal from "../../components/CategoryViewModal/CategoryViewModal";
import NewSearch from "../../components/NewSearch/NewSearch";
import Tabs from "../../components/Tabs/Tabs";
import { images } from "../../constants"
import moment from "moment/moment"
import { toast } from "react-toastify";
import PendingAlertNotification from "../../components/AlertNotification/PendingAlertNotification";
import PreviousSearch from "../../components/PreviousSearch/PreviousSearch";
import PendingSearch from "../../components/PendingSearches/PendingSearch";
import { db } from "./../../firebase/Setup/Setup";
import GenerateImages from "../../components/GenerateImages/GenerateImages";
import { getImgUrl } from "../../firebase/Storage/Storage";

import "./Category.scss";
import { AuthContext } from "../../firebase/Authentication/context";
import { Navigate } from "react-router-dom";
import LogIn from "../LogIn/LogIn";

const { collection, onSnapshot } = require("firebase/firestore");

/**
 *  Component to show  category card having a image, name  and its description
 * @param  {Array} Categories having an array of categories
 * @param  {Array}  setCategories use to assign categories data from testData
 * @component
 * @CategoryCard passing category data to this component.
 * @return  {Jsx}  It returns jsx to render categories.
 *
 * )
 */

const Category = ({activeTab,
    setActiveTab,
    generatedImage,
    setGeneratedImage,
    showResult,
    setShowResult}) => {
  const { categories = [] } =
    useSelector((state) => state.categoryState) || {};
  const {user, isTM, parentAccount} =useContext(AuthContext);
  const { loading, aiDataText2Img } = useSelector((state) => state.category2img);
  const [inputValues, setInputValues] = useState({prompt: "", width: 512, height: 512, samples: 1, prompt_strength: 1});
  const [searchesData, setSearchesData] = useState([])
  const [selectedFile, setSelectedFile] = useState([]);

  // table data for previous search
  const [pendingTableData, setPendingTableData] = useState([]);
  const [resultData, setResultData] = useState({});
  const [initialResultData, setInitialResultData] = useState({});
  // table data for pending search
  const [prevSearchData, setPrevSearchData] = useState([]);
  const [promptFormData, setPrompt] = useState("");
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [wizardFormData, setWizardFormData] = useState([   
    { preset: "", presetNegativePrompt:"", step: 1 },
    { preset: "", presetNegativePrompt:"", step: 2 },
    { preset: "", presetNegativePrompt:"", step: 3 },
    { preset: "", presetNegativePrompt:"", step: 4 },
    { preset: "", presetNegativePrompt:"", step: 5 },
    { preset: "", presetNegativePrompt:"", step: 6 },
    { preset: "", presetNegativePrompt:"", step: 7 },
    { preset: "", presetNegativePrompt:"", step: 8 },
    { preset: "", presetNegativePrompt:"", step: 9 },
    { preset: "", presetNegativePrompt:"", step: 10 },
  ]);
  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    setShowResult(false);
    setShowDot("");
  };

  /*Handling Modal Box Operation */
  const [currentIndex, setCurrentIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleCategoryModel = (index) => {
    setCurrentIndex(index);
    setOpenModal(true);
  };

  //view result
  const handleResult = () => {
    setShowResult(!showResult);
  };
//tabs
  const [showDot, setShowDot] = useState("");
  
  //categoryData
  const categoryModalData = {
    name: "Category",
    description: "Choose the best image category to start creating one",
    data: data,
  };
  const initialSetImage = async (initialResultData) => {
    let imageLink = await getImgUrl([initialResultData.init_image]);
    return setSelectedFile([{file: imageLink, name:initialResultData.init_image.split('/').pop(), path:initialResultData.init_image, isUrl:true}])   
  }
  useEffect(() => {console.log(initialResultData)
    if(initialResultData?.init_image)
      initialSetImage(initialResultData)
    else setSelectedFile([])
    categories?.private?.map((category) => {
      if(category.id === initialResultData.model_id){
        setSelectedCategory(category)}
    })
    categories?.public?.map((category) => {
      if(category.id === initialResultData.model_id)
        setSelectedCategory(category)
    })
    setInputValues((prevData) => {
      return {
        ...prevData,
        "prompt": initialResultData?.prompt ?? '',
        "samples": initialResultData?.samples ?? 1,
        "width": initialResultData?.width ?? 512,
        "height": initialResultData?.height ?? 512,
      };
    });
    if(initialResultData?.presetObject?.length)
      setWizardFormData(JSON.parse(initialResultData.presetObject))
  }, [initialResultData])

  useEffect(() => {
    let unsubscribe;
    if(user){
      const uid = isTM?parentAccount:user.uid;

      unsubscribe = onSnapshot(
        collection(db, `Users/${uid}/generationsCategory`), 
        (snapshot) => {
          let userDocs = [];
          snapshot.docs.map(doc => {
            userDocs.push(doc.data())
          })
          setSearchesData(userDocs)
        },
        (error) => {
          // console.log('error', error)
        }
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user])

  useEffect(() => {
    setPendingTableData(searchesData.filter(data => data.isDone === false))
    setPrevSearchData(searchesData.filter(data => data.isDone === true))
  }, [searchesData])


    // when click on generate this function will run
    const callbackHandler = (action) => { //(e) => {}
      //e.preventDefault();
      setLoadingAlert(true);
      if(action!=="category") setShowDot("pending");

        toast(<PendingAlertNotification />, {
          autoClose: 3000,
          hideProgressBar: true,
          className: "pending-alert",
          position: toast.POSITION.BOTTOM_RIGHT,
        });
  
      const createDate = new Date();
      const formateDate = `${moment(createDate).format("LT")}, ${moment(
        createDate
      ).format("l")}`;
  
      setPendingTableData((prevData) => {
        return [
          ...prevData,
          {
            prompt: promptFormData,
            date: formateDate,
            pending: loading,
            imgData: aiDataText2Img,
          },
        ];
      });
    };

  return (<>
    {!user?(null):(
    <div>
      <Tabs
        onClickTabItem={onClickTabItem}
        activeTab={activeTab}
        showDot={showDot}
      />
      <div className="tab-content">
        {activeTab === "newSearches" && <NewSearch
            wizardFormData={wizardFormData}
            setWizardFormData={setWizardFormData}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            inputValues={inputValues}
            setInputValues={setInputValues}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory} 
            callbackHandler={callbackHandler}
            setPrompt={setPrompt}/>}
        {activeTab === "pendingSearches" && (
          <PendingSearch
            tableData={pendingTableData}
            icon={images.TextPromptAvatar}
          />
        )}
        {activeTab === "previousSearches" && (
          <PreviousSearch
            setSelectedCategory={setSelectedCategory}
            icon={images.TextPromptAvatar}
            generatedImage={generatedImage}
            setResultData={setResultData}
            setGeneratedImage={setGeneratedImage}
            showResult={showResult}
            tableData={prevSearchData}
            handleResult={handleResult}
          />
        )}
      </div>
      {
        (showResult && activeTab === "previousSearches") && (
        <GenerateImages
          resultData={resultData}
          setInitialResultData={setInitialResultData}
          generatedImage={generatedImage}
          setGeneratedImage={setGeneratedImage}
          handleResult={handleResult}
          onClickTabItem={onClickTabItem}
        />
      )}
      {/*CategoryViewModal is component to perform pop-up operation */}
      <CategoryViewModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        cagetoryData={categoryModalData}
        index={currentIndex}
        setIndex={setCurrentIndex}
      />
    </div>)}
    </>
  );
};

export default Category;
