/**
 * @component    Image Card having a image and a download button
 */
import React, { useState } from "react";
import "./GenerateImageCard.scss";
import { images } from "../../constants";
import ImagePreview from "../ImagePreview/ImagePreview";
import { saveImage } from "../../firebase/Setup/Setup";
import PendingAlertNotification from "../../components/AlertNotification/PendingAlertNotification";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';
import { deleteImageUrlLocalStorage } from "../../firebase/Storage/Storage";

const GenerateImageCard = ({ mainImageUrl, path, reloadHandler}) => {
  //popup and Image zoom in out
  const [isOpen, setIsOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [originalZoom] = useState(1);
  const [isLoading, setIsloading] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setZoom(originalZoom);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };
  return (
    <div className="generate-card-wrapper" onClick={togglePopup}>

      <div className="generate-image-card saveImage-image">
        <img src={mainImageUrl} alt="generatedImage" />
        {isLoading && <CircularProgress style={{
          color: 'white',
          top: '43%',
          left: '43%',
          width: '40px',
          height: '40px',
          transform: 'translate(-50%, -50%)',
          position: 'absolute'
        }} />}
        <div className="button-section">
          <button onClick={(event)=>{
              event.stopPropagation();
              setIsloading(true)
              saveImage({path}).then((response)=>
              {

                if(response?.data?.status === 400){
                  setIsloading(false)
                  toast.warning('No credits left. Please upgrade your plan.', {
                    position: "top-center",
                  });
                }else{
                  if(response?.data?.message === "Image already saved.")
                    toast.info(response?.data?.message, {
                      position: "top-center",
                    });
                  if(response?.data?.message === "Saved")
                    toast.success("Saved successfully", {
                      position: "top-center",
                    });
                  deleteImageUrlLocalStorage(path)
                  setIsloading(false)
                  reloadHandler();
                }
                
              })
            }
          }
            >
            <img src={images.SaveImgIcon} alt="DownloadIcon" />
          </button>
        </div>
      </div>

      {isOpen && (
        <ImagePreview
          togglePopup={togglePopup}
          mainImageUrl={mainImageUrl}
          setZoom={setZoom}
          zoom={zoom}
        />
      )}
    </div>
  );
};

export default GenerateImageCard;
