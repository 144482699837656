import { createSlice } from "@reduxjs/toolkit";
import { img2Img } from "../Thunks/Thunks";

/**
 * @property {string} loading - api call status called in thubk
 * @property {array} data     - loading data in the data variable return from thunk
 */
const initialState = {
  loading: false,
  data: [],
  tableData: [],
};

const getFullFilledTableData = (tableData, payload) => {
  return tableData?.map((data) => {
    if (data.uId === payload?.uId) {
      return {
        ...payload,
        status: "fullfilled",
      };
    }
    return {
      ...data,
    };
  });
};
/**
 * @property {string} name  - Name of the Slice
 * @property {object} initialState  - loading the slice with the its format
 *
 */
export const img2ImgSlice = createSlice({
  name: "img2Img",
  initialState,
  reducers: {
    resetState: (state) => ({
      ...state,
      loading: false,
      success: false,
      isError: false,
    }),
  },
  /**
   * extrareducer is used when we are passing any thunk in dispatch action
   */
  extraReducers: {
    [img2Img.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        tableData: [
          ...state?.tableData,
          { ...action?.meta?.arg, status: "pending" },
        ],
      };
    },
    [img2Img.fulfilled]: (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
        success: true,
        tableData: getFullFilledTableData(
          state?.tableData,
          action?.payload?.inputData
        ),
      };
    },
    [img2Img.rejected]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
  },
});

const { reducer, actions } = img2ImgSlice;
export const { resetState } = actions;

export default reducer;
