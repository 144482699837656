/**
 * Landing page to show the statics of user usage in the form of cards and graphs
 * @component    Summary card
 */

import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { getPlanLogData } from "../../components/DashboardData/DashboardData";

import "./Landing.scss";
import { images } from "../../constants";
//import dashboardDataDummy from "../../utils/DashboardData";

// import required modules
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import TimelineGraph from "../../components/TimelineGraph/TimelineGraph";
import ConversionGraph from "../../components/ConversionGraph/ConversionGraph";
import { getDashboardDataByDate } from "../../redux/Thunks/Thunks";
import { AuthContext } from "../../firebase/Authentication/context";
import { Navigate } from "react-router-dom";
import LogIn
 from "../LogIn/LogIn";

/**
 * Landing page to show the statics of user usage in the form of cards and graphs
 * @component
 * @SummaryCard passing user usage data to this component.
 * @return  {Jsx}  It returns jsx for render the statics of user usage.
 * )
 */

const Landing = () => {
  const { user, session, isTM, parentAccount } = useContext(AuthContext);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboardState);
  //const accountData = useSelector((state) => state.account);

  //const [dashboardData, setDashboardData] = useState(dashboardDataDummy);

  const [statisticsData, setStatisticsData] =useState([])
  const [previousStatisticsData,setPreviousStatisticsData] = useState({})

  const [timeFrame, setTimeFrame] = useState("This past month");
  const [showContentDatePickers, setShowContentDatePickers] = useState(false);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // set to first hour of the day
  
  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 999); // set to last hour of the day
  
  const [date, setDate] = useState({
    start_date: today,
    end_date: endOfDay
  });

  // as per time frame it will return a date to fetch
  const dateSwitcher = (time) => {
    switch(time) {
      case "Custom Date":
        setShowContentDatePickers(true)
        break;
      default:
        setShowContentDatePickers(false)
    }
  };

  const handleTimeFrame = (e) => {
    setTimeFrame(e.target.value);
    dateSwitcher(e.target.value);
  };

  const handleChangeStartDate = (val) => {
    setDate(prev => {
      return {
        ...prev,
        start_date: moment(val.toDate()).format("LL"),
      }
    })
  }

  const handleChangeEndDate = (val) => {
    setDate(prev => {
      return {
        ...prev,
        end_date: moment(val.toDate()).format("LL"),
      }
    })
  }

  useEffect(() => {
    //dispatch(getDashboardDataByDate(date));
    if(user)
    {
      const uid = isTM?parentAccount:user.uid;
      getPlanLogData(uid, timeFrame, date, "daily").then((planLogData)=>{
        setStatisticsData(planLogData.current);

        const current = planLogData.current;
        const previous = planLogData.previous;

        // Calculate total searches, generated, and saved for current and previous periods
        const currentTotal = {
          searches: current.reduce((total, item) => total + item.Searches, 0),
          generated: current.reduce((total, item) => total + item.Generated, 0),
          saved: current.reduce((total, item) => total + item.Saved, 0),
        };
        const previousTotal = {
          searches: previous.reduce((total, item) => total + item.Searches, 0),
          generated: previous.reduce((total, item) => total + item.Generated, 0),
          saved: previous.reduce((total, item) => total + item.Saved, 0),
        };
        
        // Calculate percentage increase or decrease for each metric
        const percentageChange = {
          searches: ((currentTotal.searches - previousTotal.searches) / previousTotal.searches) * 100,
          generated: ((currentTotal.generated - previousTotal.generated) / previousTotal.generated) * 100,
          saved: ((currentTotal.saved - previousTotal.saved) / previousTotal.saved) * 100,
        };
        
        setPreviousStatisticsData({curr:currentTotal, dif: percentageChange});
      });
    }
  }, [timeFrame, date, user]);

  // these are datas to represent in graph
  const stats = data?.dashBoardData?.data?.dashboard_stats || [];

  const searchesData = stats.map((stats) => stats?.Searches);
  const generatedData = stats.map((stats) => stats?.Generated);
  const savedData = stats.map((stats) => stats?.Saved);
  const dateData = stats.map((stats) => {
    const date = new Date(stats?.Date)
    return date.toLocaleDateString()
  });
  

  return (<>
    {!user?(null):(
    <div className="dashboard-section">
      <div className="dashboard-heading-dropdown d-flex align-items-center justify-content-between flex-wrap">
        <div className="content-title">
          <h3>Hello, {user.displayName}!</h3>
        </div>
          {showContentDatePickers && <div className="content-datepickers">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={date?.start_date}
                onChange={handleChangeStartDate}
                renderInput={(params) => <TextField {...params} />}
                className="dashboard-datepicker"
              />
              <DatePicker
                label="End Date"
                value={date?.end_date}
                onChange={handleChangeEndDate}
                renderInput={(params) => <TextField {...params} />}
                className="dashboard-datepicker"
              />
            </LocalizationProvider>
          </div>
          }
        <div className="dashboard-timeframe-dropdown">
          <Form.Select
            name="timeFrame"
            value={timeFrame}
            onChange={handleTimeFrame}
          >
            <option value="Timeframe: All time">Timeframe: All time</option>
            <option value="Today">Today</option>
            <option value="This past week">This past week</option>
            <option value="This past month" defaultValue>
              This past month
            </option>
            <option value="This past year">This past year</option>
            <option value="Custom Date">Custom Date</option>
          </Form.Select>
        </div>
      </div>
      <div className="SummaryCardWrapper">
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3">
            {
              (Math.abs(previousStatisticsData?.dif?.searches).toFixed(1) === 'Infinity' || Math.abs(previousStatisticsData?.dif?.searches).toFixed(1) === 'NaN') ?
                <SummaryCard
                  CardIcon={images.SearchIcon}
                  CounterValue={previousStatisticsData?.curr?.searches}
                  DownVectorIcon={previousStatisticsData?.dif?.searches>0?images.UpVectorIcon:images.DownVectorIcon}
                  CounterPercentage={"0"}
                  CounterPercentageColor={previousStatisticsData?.dif?.searches<=0?"#ED5683":"#B5E2BC"}
                  CardText="Searches"
                /> :
                <SummaryCard
                  CardIcon={images.SearchIcon}
                  DownVectorIcon={previousStatisticsData?.dif?.searches>0?images.UpVectorIcon:images.DownVectorIcon}
                  CounterValue={previousStatisticsData?.curr?.searches}
                  CounterPercentage={`${Math.abs(previousStatisticsData?.dif?.searches).toFixed(1)}%`}
                  CounterPercentageColor={previousStatisticsData?.dif?.searches<=0?"#ED5683":"#B5E2BC"}
                  CardText="Searches"
                />
            }
          </div>

          <div className="col-md-6 col-lg-4 mb-3">
            {
              (Math.abs(previousStatisticsData?.dif?.generated).toFixed(1) === 'Infinity' || Math.abs(previousStatisticsData?.dif?.generated).toFixed(1) === 'NaN') ?
              <SummaryCard
                CardIcon={images.GenerateImageIcon}
                DownVectorIcon={previousStatisticsData?.dif?.generated>0?images.UpVectorIcon:images.DownVectorIcon}
                CounterValue={previousStatisticsData?.curr?.generated}
                CounterPercentage={"0"}
                CounterPercentageColor={previousStatisticsData?.dif?.generated<=0?"#ED5683":"#B5E2BC"}
                CardText="Generated"
              /> :
              <SummaryCard
                CardIcon={images.GenerateImageIcon}
                DownVectorIcon={previousStatisticsData?.dif?.generated>0?images.UpVectorIcon:images.DownVectorIcon}
                CounterValue={previousStatisticsData?.curr?.generated}
                CounterPercentage={`${Math.abs(previousStatisticsData?.dif?.generated).toFixed(1)}%`}
                CounterPercentageColor={previousStatisticsData?.dif?.generated<=0?"#ED5683":"#B5E2BC"}
                CardText="Generated"
              />
            }
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            {
              (Math.abs(previousStatisticsData?.dif?.saved).toFixed(1) === 'Infinity' || Math.abs(previousStatisticsData?.dif?.saved).toFixed(1) === 'NaN') ?
              <SummaryCard
                CardIcon={images.SaveImageIcon}
                DownVectorIcon={previousStatisticsData?.dif?.saved>0?images.UpVectorIcon:images.DownVectorIcon}
                CounterValue={previousStatisticsData?.curr?.saved}
                CounterPercentage={"0"}
                CounterPercentageColor={previousStatisticsData?.dif?.saved<=0?"#ED5683":"#B5E2BC"}
                CardText="Saved"
              /> :
              <SummaryCard
                CardIcon={images.SaveImageIcon}
                DownVectorIcon={previousStatisticsData?.dif?.saved>0?images.UpVectorIcon:images.DownVectorIcon}
                CounterValue={previousStatisticsData?.curr?.saved}
                CounterPercentage={`${Math.abs(previousStatisticsData?.dif?.saved).toFixed(1)}%`}
                CounterPercentageColor={previousStatisticsData?.dif?.saved<=0?"#ED5683":"#B5E2BC"}
                CardText="Saved"
              />
            }
          </div>
        </div>
      </div>

      <div style={{ marginBottom: 150, marginTop: 27 }} className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 mt-3">
          <TimelineGraph
            graphData={statisticsData}
            dateData={date}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 mt-3">
          <ConversionGraph
            graphData={statisticsData}
          />
        </div>
      </div>
    </div>)}
    </>
  );
};

export default Landing;
