import React, { useState, useEffect, useContext } from "react";
import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from "firebase/auth";
import "./Password.scss";
import { AuthContext } from "../../../../firebase/Authentication/context";
import { toast } from "react-toastify";




const Passoword = () => {
  const {user} = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const [error, setError] = useState(null);
  const [isEqual, setIsEqual] = useState(false);


  const clearAllFields = ()=>{
    setEmail("");
    setPassword("");
    setNewPassword("");
    setRepeatNewPassword("");
  }
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const handleRepeatNewPasswordChange = (event) => {
    setRepeatNewPassword(event.target.value);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if(isEqual && user && newPassword.length>0)
    {

    const credential = getEmailAndPasswordCredential(email, password);

    reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            toast.success("User password updated successfully", {
              position: "top-center",
            });
            clearAllFields();
          })
          .catch((error) => {
            toast.error("Could not change password. Please try again.", {
              position: "top-center",
            });
            
            setError(error.message);
          });
      })
      .catch((error) => {
        toast.error("Wrong credentials provided", {
          position: "top-center",
        });
        setError(error.message);
      });
    }else{
      setError("Passwords don't match or user is not logged in.")
    }
  };

  const getEmailAndPasswordCredential = (email, password) => {
    return EmailAuthProvider.credential(email, password);
  };


  useEffect(()=>{
    if(newPassword===repeatNewPassword)
    {
      setIsEqual(true);
    }else{
      setIsEqual(false);
    }

  },[newPassword, repeatNewPassword])



  return (
    <div className="user-profile-password row">
      <div className="col-md-6 col-sm-12">
        <form onSubmit={handleSubmit}>
        <div className="mb-3 d-flex align-items-center flex-wrap flex-lg-nowrap flex-md-nowrap">
          <label className="form-label">
            Email:
            <input type="email" value={email} onChange={handleEmailChange} className="form-control" placeholder="Enter your current email"/>
          </label>
        </div>
        <div className="mb-3 d-flex align-items-center flex-wrap flex-lg-nowrap flex-md-nowrap">
          <label className="form-label">
            Password:
            <input type="password" value={password} onChange={handlePasswordChange} className="form-control" placeholder="Enter your current password"/>
          </label>
        </div>
        <div className="mb-3 d-flex align-items-center flex-wrap flex-lg-nowrap flex-md-nowrap">
          <label className="form-label">
            New Password:
            <input type="password" value={newPassword} onChange={handleNewPasswordChange} className="form-control" placeholder="Enter your new password"/>
          </label>
        </div>
        <div className="mb-3 d-flex align-items-center flex-wrap flex-lg-nowrap flex-md-nowrap">
          <label className="form-label">
            New Password:
            <input type="password" value={repeatNewPassword} onChange={handleRepeatNewPasswordChange} onPaste={()=>{return false;}} className="form-control" placeholder="Repeat your new password"/>
          </label>
        </div>
          <button type="submit" className="btn btn-primary btn-yellow mt-4">Update Password</button>
        </form>
        {error && <p>Cannot update user password at the moment. Please try again later or contact the administrator.</p>}
      </div>
    </div>
  );
};

export default Passoword;
