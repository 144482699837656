import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable, request } from "firebase/functions";

/**
 * creating firebase config object with all secret keys
 */
export const firebaseConfig = {
  apiKey: "AIzaSyCewN9gECorNSlDKlF47ggIWd1_tYNaSc0",
  authDomain: "pixelbomb-98b8d.firebaseapp.com",
  projectId: "pixelbomb-98b8d",
  storageBucket: "pixelbomb-98b8d.appspot.com",
  messagingSenderId: "414519948603",
  appId: "1:414519948603:web:f1c59080a3926299cd331e",
  measurementId: "G-CRCQC394E9"
};

/**
 * Creates and initializes a firebase app
 */
export const app = initializeApp(firebaseConfig);

/**
 * Returns the Auth instance associated with the provided firebase app
 */
export const firebaseAuth = getAuth(app);

/**
 * Returns the existing default Firestore instance that is associated with the provided firebase
 */
export const db = getFirestore(app);


const func = getFunctions(app);

//connectFunctionsEmulator(func, "localhost", 5001);
//connectAuthEmulator(firebaseAuth,"http://localhost:9099");

export const getUserInformation = httpsCallable(func,'getUserInformation')
export const text2img = httpsCallable(func,'text2img')
export const img2img = httpsCallable(func,'img2img')
export const saveImage = httpsCallable(func,'saveImage')
export const getPresets = httpsCallable(func,'getPresets')
export const getUserSavedImages = httpsCallable(func,'getUserSavedImages')
export const getAllCategoriesFunc = httpsCallable(func,'getCategories')
export const createCategoryFunc = httpsCallable(func,'createCategoryV2')
export const text2imgFromCategory = httpsCallable(func,'text2imgFromCategory')
export const img2imgFromCategory = httpsCallable(func,'img2imgFromCategory')

export const getNewCategoryPercentageOfCompletion = httpsCallable(func,'getNewCategoryPercentageOfCompletion')
export const downloadImage = httpsCallable(func, 'downloadImage');
export const createTeamMember = httpsCallable(func, 'createTeamMember');
export const deleteTeamMember = httpsCallable(func, 'deleteTeamMember');

export const creditCheck = httpsCallable(func, 'creditCheck');
export const getIp = httpsCallable(func, 'getIp');

