/**
 * @component    Category Card having a image, name  and its description
 */

import React, { useContext, useEffect } from "react";

import Edit from "../../assets/Icons/edit-fill.svg";
import Lock from "../../assets/Icons/lock.svg";
import "./CategoryCard.scss";
import LinearProgress from "@mui/material/LinearProgress";
import {getSingleImgUrl} from "../../firebase/Storage/Storage"
import { useState } from "react";
import { toast } from "react-toastify";
import {getNewCategoryPercentageOfCompletion} from "../../firebase/Setup/Setup"
import { AuthContext } from "../../firebase/Authentication/context";
/**
 *@component Component to show  category card having a image, name  and its description
 *
 * @param  {String} name The category name
 * @param  {String}  mainImageUrl  Category Image
 * @param  {String}  description Category description
 * @return  {Jsx}  It returns jsx for render a category card.
 *
 */

const CategoryCard =  ({ name, mainImageUrl, description, isPrivate, id, cid}) => {

  const [imgUrl, setImgUrl] = useState(mainImageUrl)
  const [isTraining, setIsTraining] = useState(true)
  const [etaPer, setEtaPer] =useState(0)
  const [isError, setIsError] = useState(false)
  const {user} = useContext(AuthContext);

  useEffect(() => {
    if(user) {
      if (isPrivate) {
        getSingleImgUrl(mainImageUrl)
          .then((url) => {
            setImgUrl(url);
          })
          .catch((err) => {
            toast.error('Please refresh the page and try again!', {
              position: "top-center",
            });
            // console.log(err?.message);
          });
      }
    }
  }, [isPrivate, mainImageUrl]);

  useEffect(() => {
    if (id === "NULL" && etaPer === 100) {
      setIsError(true);
      setIsTraining(false);
    }
  }, [id, etaPer]);

  useEffect(() => {
    if(user)
    {
      if (isPrivate && etaPer !== 100) {
        const intervalId = setInterval(() => {
          getNewCategoryPercentageOfCompletion({ category: cid }).then((ret) => {
            setEtaPer(ret.data.per);
          });
        }, 20000);
        getNewCategoryPercentageOfCompletion({ category: cid }).then((ret) => {
          setEtaPer(ret.data.per);
        });
        return () => clearInterval(intervalId);
      }
    }
  }, [isPrivate, cid]);

  return (
    <>
      {!isTraining && isPrivate?
        (<div className="category-card">
            <div className="card-image"  style={{ position: 'relative' }}>
              <img src={imgUrl} style={{opacity:"0.2"}} alt="category"/>
              {isError?( <p style={{ position: "absolute", top: "45%", left: "50%", transform: "translate(-50%, -50%)", color: "red", textAlign: "center" }}>There's been a problem with the training!</p>):(null)}
              
            </div>
            <div className="category-card_content">
              <h2>{name}</h2>
              <p>{description}</p>
            </div>
          </div>)
        :
        (<>
          <div className="category-card">
            <div className="card-image"  style={{ position: 'relative' }}>
              {id==="NULL" && isPrivate?
                (<>
                  <img src={imgUrl} alt="category" style={{opacity:"0.5"}}/>
                  <div style = {
                    {
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "black",
                      textAlign: "center",
                      width: "85%"
                    }
                  } >
                    <p style={{backgroundColor: 'rgba(255,255,255, 0.7)', color: 'black', borderRadius: '5px'}}>Category Under Construction</p>
                    <p style={{backgroundColor: 'rgba(255,255,255, 0.7)', color: 'black', borderRadius: '5px 5px 0 0'}}>{etaPer}%</p>
                  </div>
                  <LinearProgress style={{ borderRadius: '0 0 5px 5px', position: "absolute", top: "55%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center",width: "85%",}}/>
                  <div style = {
                    {
                      position: "absolute",
                      bottom: "25%",
                      left: "50%",
                      transform: "translate(-50%, 50%)",
                      color: "black",
                      textAlign: "center",
                      width: "85%"
                    }
                  } >
                    <p style={{backgroundColor: 'rgba(255,255,255, 0.7)', color: 'black', borderRadius: '5px'}}>Can Take Up to 45 Minutes</p>
                  </div>
                </>):
                (<img src={imgUrl}alt="category" />)
              }
            </div>
            {isPrivate && <div className="private_label">
              <img src={Lock} alt="" />
              <span>Private</span>
            </div>}
            <div className="category-card_content">
              <h2>{name}</h2>
              <p>{description}</p>
            </div>
          </div>
          </>)
      }
    </>
  );
};

export default CategoryCard;
