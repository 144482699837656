import {app} from "../Setup/Setup"
import { getStorage, ref, getDownloadURL, uploadString } from "firebase/storage";
import { toast } from "react-toastify";

function getImageUrlLocally(imgPath) {
    const images = JSON.parse(localStorage.getItem('images')) || []; // Get existing images array from localStorage or initialize as empty array
    const existingImage = images.find(image => image.imgPath === imgPath); // Check if image with imgPath already exists
  
    if (existingImage) {
      return existingImage.imgUrl; // If image exists, return corresponding imgUrl
    } else {
      return null;
    }
  }

function setImageUrlLocally(imgPath,url) {
const images = JSON.parse(localStorage.getItem('images')) || []; // Get existing images array from localStorage or initialize as empty array
const newImage = { imgPath, imgUrl: url }; // Create new image object with imgPath and imgUrl
images.push(newImage); // Add new image to images array
localStorage.setItem('images', JSON.stringify(images)); // Save updated images array to localStorage
}

export const deleteImageUrlLocalStorage = (imgPath) => {
    let images = JSON.parse(localStorage.getItem('images')) || []; // Get existing images array from localStorage or initialize as empty array
    let existingImage = images.find((image) => image.imgPath === imgPath); // Check if image with imgPath already exists
  
    if (existingImage) {
      images = images.filter((image) => image.imgPath !== imgPath); // Remove the row from the JSON array that corresponds to existingImage
      localStorage.setItem('images', JSON.stringify(images)); // Save updated images array to localStorage
    }
  };

export const getImgUrl = async (imgArray)=> {

    let urls = [];
    const promises = imgArray.map(async (img) => {
        const imgUrl = getImageUrlLocally(img);
        if(imgUrl===null){
            const storage = getStorage(app);

            return await getDownloadURL(ref(storage, img))
                .then((url) => {
                    setImageUrlLocally(img, url)
                    return url;
                })
                .catch((err) => {
                    toast.error('Please refresh the page and try again!', {
                        position: "top-center",
                    });
                });
        }else{
            return Promise.resolve(imgUrl);
        }
    });

    await Promise.all(promises)
        .then((results) => {
            urls = results;
        });

    return urls;
};

export const getImgUrlAndPath = async (imgArray)=> {

    let urls = [];
    const promises = imgArray.map(async (img) => {
        const imgUrl = getImageUrlLocally(img);
        if(imgUrl===null){
            const storage = getStorage(app);

            return await getDownloadURL(ref(storage, img))
                .then((url) => {
                    setImageUrlLocally(img, url)
                    return {url:url, path:img};
                })
                .catch((err) => {
                    toast.error('Please refresh the page and try again!', {
                        position: "top-center",
                    });
                });
        }else{
            return Promise.resolve({url:imgUrl, path:img});
        }
    });

    await Promise.all(promises)
        .then((results) => {
            urls = results;
        });

    return urls;
};

export const getSingleImgUrl = async (path)=> {

    const imgUrl = getImageUrlLocally(path);
    if(imgUrl===null){
        const storage = getStorage(app);
        try {
            const url = await getDownloadURL(ref(storage, path));
            setImageUrlLocally(path, url)
            return url;
        } catch (err) {
            toast.error('Please refresh the page and try again!', {
                position: "top-center",
            });
            return null; // Or you can throw an error here
        }
    }else{
        return imgUrl;
    }
};

const getBase64MimeType = (encoded) => {
    let result = null
    if (typeof encoded !== 'string') {
        return result
    }
    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
    if (mime && mime.length) {
        result = mime[1]
    }
    return result
}

const getBase64Data = (encoded) => {
    const base64EncodedString = encoded.replace(/^data:\w+\/\w+;base64,/,'')
    return base64EncodedString
}



 const uploadPublicImage = async (fileName, uid , imageData,timestamp) => {

    const storage = getStorage(app);
    const path = `${uid}/categories/${timestamp}/pub_image/${fileName}`;
    const fileRef = ref(storage,path);

    const contentType = getBase64MimeType(imageData)
    const photoData = getBase64Data(imageData)

    const imgOptions = {
        contentType,
    }

    const uploadTask = uploadString(fileRef,photoData, 'base64', imgOptions);
    await uploadTask;   

    // Get a public URL 
    return await getDownloadURL(fileRef);
}



const getTrainingImageRef = (fileName, uid,timestamp) => {
    const storage = getStorage(app);
    const path = `${uid}/categories/${timestamp}/training_images/${fileName}`;
    return {ref: ref(storage,path), path:path};
}


async function uploadImage(fileRef, imageData) {
  
    const storage = getStorage(app);

    const contentType = getBase64MimeType(imageData)
    const photoData = getBase64Data(imageData)
  
    const imgOptions = {
      contentType,
    }
    
    // Convert the base64-encoded string to a Blob
    const uploadTask = uploadString(fileRef,photoData, 'base64', imgOptions);
    await uploadTask;
}



const uploadMultipleImages = async(files, uid, timestamp) => {

    let fileList = []
    for (let index = 0; index < files.length; index++) {
        const file = getTrainingImageRef(files[index].name, uid, timestamp);
        fileList.push(file.path);
        await uploadImage(file.ref, files[index].data)
    }
  
    return fileList;
}

export const uploadTrainingImages = async(selectedFile, uid) =>{
    const timestamp = String(Date.now());
    const paths = await uploadMultipleImages(selectedFile, uid, timestamp);
    const url = await uploadPublicImage(selectedFile[0].name, uid, selectedFile[0].data, timestamp);
    const obj={
        timestamp: timestamp,
        paths: paths,
        mainUrl: url,
    }
    return obj;
}
  