import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { images } from "../../constants";
import { firebaseAuth } from "../../firebase/Setup/Setup";
import { sendPasswordResetEmail } from "firebase/auth";

const PasswordRecovery = () => {


  const [inputValues, setInputValues] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const { email } = inputValues;
    if (email) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputValues]);

  const inputChangeHandle = (e) => {
    const { name, value } = e.target || {};
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    
    sendPasswordResetEmail(firebaseAuth, inputValues.email)
      .then(() => {
        setIsLoading(false);
        toast.success("Password reset email sent! Please check your inbox.", {
          position: "top-center",            
          onClose:() => {window.location.href="/"},
        })
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error('Please refresh the page and try again!', {
          position: "top-center",
        });
        // console.log(errorCode,errorMessage);
      });

    
  };

  return (
    <div className="signLog">
      {/* <div className="container"> */}
      <div className="signLog_container">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-8 col-md-6">
            <div className="header-wrapper d-flex align-items-center justify-content-between">
              <div className="brand d-flex align-items-center ">
                <img src={images.logo} alt="logo" className="brand_logo" />
              </div>
              <NavLink to="/login" className="signLog_link">
                Log In
              </NavLink>
            </div>

            <div className="signLog_wrapper">
              <div className="content-title">
                <h3>Recover your password</h3>
                <span>
                  Already have an account?
                  <NavLink to="/login" className="signLog_link ms-2">
                    Log In
                  </NavLink>
                </span>
              </div>
              <div>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter email address"
                    onChange={inputChangeHandle}
                  ></input>
                </div>
                <div className="mb-3">
                  <button
                    className="btn btn-primary w-100 btn-yellow"
                    type="submit"
                    name="submit"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    {isLoading ? (
                      <CircularProgress
                        style={{
                          width: "25px",
                          color: "white",
                          height: "25px",
                        }}
                      />
                    ) : (
                      "Recover Your Password"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 rightImg">
            <img src={images.LogBg} alt="" className="signLog_image" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PasswordRecovery;
