import React, { useState } from "react";
import "./SavedImageCard.scss";
import { images } from "../../constants";
import ImagePreview from "../ImagePreview/ImagePreview";
import { downloadImage } from "../../firebase/Setup/Setup";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";


/**
 *@component SavedImageCard Component to show saved Image card having a image, name  and its description
 *
 * @param  {String}  mainImageUrl  Category Image
 * @param  {String}  date Category description
 * @param  {String}  imageTitle Category description
 * @return  {Jsx}  It returns jsx for render a category card.
 *
 */

const SavedImageCard = (props) => {
  const { url, path, date, imageTitle } = props;
  //popup and Image zoom in out
  const [isOpen, setIsOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [originalZoom] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
    setZoom(originalZoom);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };


  const handleDownloadImage = async (path) => {
    setIsDownloading(true)
    try {
      const response = await downloadImage({ path });
      const base64 = response.data;
      const url = `data:image/png;base64,${base64}`;
      const a = document.createElement('a');
      a.download = path;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsDownloading(false)
    } catch (error) {
      toast.error('Please refresh the page and try again!', {
        position: "top-center",
      });
      setIsDownloading(false)
    }
  }
  return (
    <div className="saveImage-card-wrapper">
      <div className="saveImage-image-card">
        <div className="saveImage-image">
          <div className="button-section">
            <button onClick={() => handleDownloadImage(path)}>
            {!isDownloading?
              (<img src={images.DownloadIcon} alt="DownloadIcon" />):
              (<CircularProgress size="1rem" color="inherit"/>)}
            </button>
            <button onClick={togglePopup}>
              <img src={images.SearchIcon1} alt="EditIcon" />
            </button>
          </div>
          <img src={url} alt="saveImagedImage" />
        </div>
        <div className="imgTitle">{imageTitle}</div>
        <div className="imgDate">{date}</div>
      </div>
      {isOpen && (
        <ImagePreview
          togglePopup={togglePopup}
          mainImageUrl={url}
          setZoom={setZoom}
          zoom={zoom}
        />
      )}
    </div>
  );
};

export default SavedImageCard;
