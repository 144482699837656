import { db } from "../../firebase/Setup/Setup";
const {getDoc, doc} = require("firebase/firestore");

export async function getPlanLogData(uid, timeframe, dateRange, timeInterval) {
    const userRef = doc(db, 'Users', uid);
    const userDoc = await getDoc(userRef);
    const planLog = userDoc.data().planlog;
    let localTimeInterval=timeInterval;

    if(planLog!==undefined) {
        let filteredData = planLog;
        let prevFilteredData = planLog;


       // console.log(filteredData);

        // Filter data based on date range
        let startDate, endDate, prevStartDate, prevEndDate;
        const today = new Date();
        const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        const tenYearsAgo = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate());

        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const twoWeekAgo = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
        const twoMonthAgo = new Date(today.getFullYear(), today.getMonth() - 2, today.getDate());
        const twoYearAgo = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());

        switch(timeframe) {
            case "This past week":
                localTimeInterval="daily"

                startDate = oneWeekAgo;
                endDate = today;

                prevStartDate = twoWeekAgo;
                prevEndDate = yesterday;

                filteredData = filteredData.filter((item) => {
                    return item.date.toDate() >= oneWeekAgo;
                });
                prevFilteredData = prevFilteredData.filter((item) => {
                    return item.date.toDate() >= twoWeekAgo &&item.date.toDate() < oneWeekAgo;
                });
                break;
            case "This past month":
                localTimeInterval="daily"

                startDate = oneMonthAgo;
                endDate = today;

                prevStartDate = twoMonthAgo;
                prevEndDate = oneMonthAgo;

                filteredData = filteredData.filter((item) => {
                    return item.date.toDate() >= oneMonthAgo;
                });
                prevFilteredData = prevFilteredData.filter((item) => {
                    return item.date.toDate() >= twoMonthAgo && item.date.toDate() < oneMonthAgo;;
                });
                break;
            case "This past year":
                localTimeInterval="daily"

                startDate = oneYearAgo;
                endDate = today;

                prevStartDate = twoYearAgo;
                prevEndDate = oneYearAgo;

                filteredData = filteredData.filter((item) => {
                    return item.date.toDate() >= oneYearAgo;
                });
                prevFilteredData = prevFilteredData.filter((item) => {
                    return item.date.toDate() >= twoYearAgo && item.date.toDate() < oneYearAgo;
                });
                break;
            case "Timeframe: All time":
                localTimeInterval="daily"
                startDate = tenYearsAgo;
                endDate = today;

                prevStartDate = tenYearsAgo;
                prevEndDate = today;
                break;
            case "Today":
                localTimeInterval="daily"
                // Custom date range
                startDate = new Date();
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date()
                endDate.setHours(23, 59, 59, 999);
                // Calculate previous period's start and end dates
                prevStartDate = new Date(startDate);
                prevStartDate.setDate(startDate.getDate() - (endDate.getDate() - startDate.getDate() + 1));
                prevEndDate = new Date(endDate);
                prevEndDate.setDate(endDate.getDate() - (endDate.getDate() - startDate.getDate() + 1));

                filteredData = filteredData.filter((item) => {
                    return item.date.toDate() >= startDate && item.date.toDate() <= endDate;
                });
                prevFilteredData = prevFilteredData.filter((item) => {
                    return item.date.toDate() >= prevStartDate && item.date.toDate() <= prevEndDate;
                });
                break;
            default:
                // Custom date range
                startDate = new Date(dateRange.start_date);
                endDate = new Date(dateRange.end_date);

                // Calculate previous period's start and end dates
                prevStartDate = new Date(startDate);
                prevStartDate.setDate(startDate.getDate() - (endDate.getDate() - startDate.getDate() + 1));
                prevEndDate = new Date(endDate);
                prevEndDate.setDate(endDate.getDate() - (endDate.getDate() - startDate.getDate() + 1));

                filteredData = filteredData.filter((item) => {
                    return item.date.toDate() >= startDate && item.date.toDate() <= endDate;
                });
                prevFilteredData = prevFilteredData.filter((item) => {
                    return item.date.toDate() >= prevStartDate && item.date.toDate() <= prevEndDate;
                });
                break;
        }
        
          

        // Group data based on time interval
        let groupedData = filteredData.reduce((acc, item) => {
            let key;
            switch(localTimeInterval) {
            case "hourly":
                key = item.date.toDate().getHours();
                break;
            case "daily":
                key = item.date.toDate().toDateString();
                break;
            case "weekly":
                key = "Week " + getWeekNumber(item.date.toDate());
                break;
            case "monthly":
                key = item.date.toDate().toLocaleString('default', { month: 'long', year: 'numeric' });
                break;
            case "yearly":
                key = item.date.toDate().getFullYear();
                break;
            }

            if (!acc[key]) {
            acc[key] = {
                generated: 0,
                searched: 0,
                saved: 0,
                categories: 0
            };
            }

            acc[key].generated += item.generated;
            acc[key].searched += item.searched;
            acc[key].saved += item.saved;
            acc[key].categories += item.categories;

            return acc;
        }, {});

        
        // Group data based on time interval
        let prevGroupedData = prevFilteredData.reduce((acc, item) => {
            let key;
            switch(localTimeInterval) {
            case "hourly":
                key = item.date.toDate().getHours();
                break;
            case "daily":
                key = item.date.toDate().toDateString();
                break;
            case "weekly":
                key = "Week " + getWeekNumber(item.date.toDate());
                break;
            case "monthly":
                key = item.date.toDate().toLocaleString('default', { month: 'long', year: 'numeric' });
                break;
            case "yearly":
                key = item.date.toDate().getFullYear();
                break;
            }

            if (!acc[key]) {
            acc[key] = {
                generated: 0,
                searched: 0,
                saved: 0,
                categories: 0
            };
            }

            acc[key].generated += item.generated;
            acc[key].searched += item.searched;
            acc[key].saved += item.saved;
            acc[key].categories += item.categories;

            return acc;
        }, {});



       // console.log(JSON.stringify(groupedData));

        return {current:parseDashboardData(groupedData,startDate, endDate, localTimeInterval), previous: parseDashboardData(prevGroupedData, prevStartDate, prevEndDate, localTimeInterval)};
    }
    return null;
}

// Helper function to get week number
function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
}


function parseDashboardData(data, startDate, endDate, interval) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const intervalMs = getIntervalMs(interval);
    const result = [];
  
    for (let dateObj = startDateObj; dateObj <= endDateObj; dateObj = new Date(dateObj.getTime() + intervalMs)) {
      let key;
      switch(interval) {
        case "hourly":
          key = dateObj.getHours();
          break;
        case "daily":
          key = dateObj.toDateString();
          break;
        case "weekly":
          key = "Week " + getWeekNumber(dateObj);
          break;
        case "monthly":
          key = dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });
          break;
        case "yearly":
          key = dateObj.getFullYear();
          break;
      }
      const dateData = data[key] || { generated: 0, searched: 0, saved: 0, categories: 0 };
      result.push({
        Searches: dateData.searched,
        Generated: dateData.generated,
        Saved: dateData.saved,        
        Categories: dateData.categories,
        Date: formatDateForDisplay(dateObj),
      });
    }
  
    return result;
  }
  
  
  function getIntervalMs(interval) {
    switch (interval) {
      case 'hourly':
        return 60 * 60 * 1000;
      case 'daily':
        return 24 * 60 * 60 * 1000;
      case 'monthly':
        return 30 * 24 * 60 * 60 * 1000;
      case 'yearly':
        return 365 * 24 * 60 * 60 * 1000;
      default:
        throw new Error(`Invalid interval: ${interval}`);
    }
  }
  
  function formatDate(dateObj) {
    return dateObj.toDateString();
  }
  
  function formatDateForDisplay(dateObj) {
    return dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour:'numeric'});
  }