import React from "react";
import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";
import { images } from "../../constants";

/**
 * Component to open alert notification when user click on generate button.
 *
 * @GenerateAlertNotification
 * @example
 * @param   {<any>} loadingAlert <First Opens the Loading alert Modal>
 * @param   {<any>} setLoadingAlert <Sets the state to open the Loading alert modal>
 * @param   {<any>} imageGeneratedAlert <Opens the Generated Image Modal>
 * @param   {<any>} setImageGeneratedAlert <Sets the state to open the Generated Image alert modal>
 * @return  {Jsx} It returns the alert modal notification
 * )
 */

export const GenerateAlertNotification = ({
  loadingAlert,
  imageGeneratedAlert,
  setImageGeneratedAlert,
  showGenerated,
}) => {
  return (
    <div>
      <ToastContainer className="position-fixed bottom-0 end-0 p-3">
        <Toast
          show={imageGeneratedAlert}
          onClose={() => setImageGeneratedAlert(false)}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto" style={{ color: "black" }}>
              Your image was generated!
            </strong>
          </Toast.Header>
          <Toast.Body>
            <div className="d-flex justify-content-center mt-2">
              <img src={images.Done} className="ml-n" alt="done" />
              <div className="ms-2">
                Your image was successfully generated.You can view image now.
              </div>
            </div>

            <div className="d-flex justify-content-center mt-2">
              <button className="btn btn-goback ms-2">View Now</button>
              <button
                className="btn btn-goback ms-2"
                onClick={() => setImageGeneratedAlert(false)}
              >
                Later
              </button>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ToastContainer className="position-fixed bottom-0 end-0 p-3">
        <Toast show={loadingAlert} onClose={showGenerated} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto" style={{ color: "black" }}>
              Generating...
            </strong>
          </Toast.Header>
          <Toast.Body>
            <div className="d-flex justify-content-center mt-2">
              <img src={images.Loading} className="ml-n" alt="done" />
              <div className="ms-2">
                Please wait for a few seconds, your request is being processed.
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};
