import React, {useEffect, useState} from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ImageUploader from "../../components/ImageUploader/ImageUploader";
import RangeSlider from "../../components/RangeSlider/RangeSlider";
// import wizardPresetsData from "../../utils/WizardPresetsData";
import WizardPopup from "../../components/WizardPopup/WizardPopup";
import { images } from "../../constants";
import { getPresets } from "./../../firebase/Setup/Setup";
import { toast } from "react-toastify";
import Loader from "./../../components/Loader/Loader";

function UseImageNewSearch({generateHandler, newSearchValueChangeHandle, handleSizeChange, setWizardFormData, wizardFormData, newSearchValues, toggleWizard, isOpen, setNewSearchValues}) {
  const [selectedFile, setSelectedFile] = useState([]);
  const [step, setStep] = useState(1);
    // wizard - form data

  const [wizardPresetsData, setWizardPresetData] = useState(null);
  useEffect(() => {
    getPresets()
      .then((presets) => {
        setWizardPresetData(presets.data);
      })
      .catch((err) => {
        toast.error('Please refresh the page and try again!', {
          position: "top-center",
        });
        console.log(err?.message);
      });
  }, []);
  const removeSelectedImage = (index) => {
    let arr = [...selectedFile];
    arr.splice(index, 1);
    setSelectedFile((current) => [...arr]);
    document.getElementById('file').value = ''
  };

  // wizard - finish
  const handleFinish = () => {
    const wizard = wizardFormData?.map((data) => data.preset);
    setNewSearchValues((prevData) => {
      return {
        ...prevData,
        wizard,
      };
    });
    toggleWizard();
    setStep(1);
  };

  useEffect(() => {
    newSearchValueChangeHandle({target: {name: 'file', value: selectedFile.length ? selectedFile[0].file : ''}})
    newSearchValueChangeHandle({target: {name: 'file_name', value: selectedFile.length ? selectedFile[0].name : ''}})
  }, [selectedFile])

  return (
    wizardPresetsData ? 
      <div className="tab-content_wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className=" col-12 col-lg-7 col-xl-7">
              <div className="content-title">
                <h3>Upload A Reference Image</h3>
                <span>Upload a reference to create more images</span>
              </div>

              <button
                className="btn btn-wizard float-end mb-4"
                onClick={toggleWizard}
              >
                <img src={images.Magic} alt="" className="me-1" />
                Wizard
              </button>

              {isOpen && (
                <WizardPopup
                  step={step}
                  setStep={setStep}
                  handleFinish={handleFinish}
                  promptFormData={newSearchValues}
                  handlePromptData={newSearchValueChangeHandle}
                  setWizardFormData={setWizardFormData}
                  wizardFormData={wizardFormData}
                  toggleWizard={toggleWizard}
                  presetsData={wizardPresetsData.presets}
                  toShowsData={wizardFormData[step - 1]}
                />
              )}

              <ImageUploader
                image={images.ImageUploadIcon}
                text="Drag an image here or"
                uploadFileText="upload a file"
                onFileSelectSuccess={(file) => {
                  let reader = new FileReader();
                  reader.onload = function (e) {
                    let pngBase64Prefix = 'data:image/png;base64,';
                    let imageData = e.target.result;
                    let fileName = file.name
                    if(imageData.includes(';base64,')) {
                      imageData = pngBase64Prefix + imageData.split(';base64,')[1];
                      fileName = fileName.split('.')[0] + '.png';
                    }
                    // setSelectedFile((current) => [...current, e.target.result]);
                    setSelectedFile((current) => [
                      { file: imageData, name: fileName },
                    ]);
                  };
                  reader.readAsDataURL(file);
                }}
              />



              <div className="swiper-slider-wrapper">
                <Swiper
                  className="swiperSlider"
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={10}
                  slidesPerView={5}
                  navigation
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  {selectedFile.map((item, index) => {
                    return (
                      <div key={index}>
                        <SwiperSlide>
                          <div className="swiperSlider-item">
                            <img
                              src={item.file}
                              alt=""
                              className="swiperSlider-image"
                            />
                            <button
                              onClick={() => removeSelectedImage(index)}
                              className="swiper-slider-close-icon"
                              type="button"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </SwiperSlide>
                      </div>
                    );
                  })}
                </Swiper>
              </div>

              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="prompt"
                  value={newSearchValues.prompt}
                  onChange={newSearchValueChangeHandle}
                  rows="4"
                  placeholder="Eg. A girl with long blonde hair and big eyes in the grass at sunset"
                ></textarea>
              </div>

              <form className="mt-1">
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="form-title">
                      <h4>How wild do you want the AI to go?</h4>
                      <span className="sub-title">
                        Decide how “wild” the image generation process should be
                        from 1 to 10 - destruction of the image.
                      </span>
                    </div>
                  </div>
                  <div className="form-group mt-3 ">
                    <div className="field d-flex align-items-center justify-content-center">
                      <span className="value left">1</span>
                      <RangeSlider
                        max={10}
                        name="prompt_strength"
                        labelSwitch="auto"
                        value={newSearchValues.prompt_strength}
                        handlePromptData={newSearchValueChangeHandle}
                      />
                      <span className="value right">10</span>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="form-title">
                      <h4>Quantity</h4>
                      <span className="sub-title">
                        The number of image to generate
                      </span>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="field d-flex align-items-center justify-content-center">
                      <span className="value left">1</span>
                      <RangeSlider
                        max={3}
                        handlePromptData={newSearchValueChangeHandle}
                        name="quantity"
                        labelSwitch="auto"
                        value={newSearchValues.quantity}
                      />
                      <span className="value right">3</span>
                    </div>
                  </div>
                </div>
                <div className="use-image-generate-button">
                  <div className="btn-generate btn-yellow" onClick={generateHandler}>
                    Generate
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> :
      <Loader />
  );
}

export default UseImageNewSearch;
