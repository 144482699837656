import React, { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { images } from "../../constants";
import { firebaseAuth } from "../../firebase/Setup/Setup";
import { sendPasswordResetEmail } from "firebase/auth";

import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { useWindowScroll } from "react-use";

/**
 * Ressets password according to firebase requirements
 * @returns 
 */
const PasswordReset = () => {

  const [urlParams, setUrlParams] = useSearchParams();
  const [code, setCode] = useState(urlParams.get("oobCode"));
  //const [mode, setMode] = useState(""); //What to use for?
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [isSamePasswords, setIsSamePasswords] = useState(true);

  //setMode(urlParams.get("mode"));

 
  const clearAllFields=() => {

    setNewPassword("");
    setNewPasswordRepeat("");
  }

  useEffect(() => {
    if(code!="") {
      //Verify if oobCode is good
      verifyPasswordResetCode(firebaseAuth,code).then(()=> {
        setShowResetForm(true);
      }).catch(()=> {
        setShowResetForm(false);
        toast.error("Password reset code has expired. We will redirect you to the password-recovery page now.", {
          position: "top-center",          
          onClose:() => {window.location.href="/password-recovery"},
        })
      })
    }

  }, [code]);

  useEffect(() => {
    if (newPassword!==newPasswordRepeat) {
      setIsDisabled(true);
      setIsSamePasswords(false);
    } else {
      setIsDisabled(false);
      setIsSamePasswords(true);
    }
  }, [newPassword,newPasswordRepeat]);

 
  const handleSubmit = () => {
    if(newPassword === newPasswordRepeat){
    setIsLoading(true);
    confirmPasswordReset(firebaseAuth, code, newPassword)
      .then(() => {
        setIsLoading(false);
        clearAllFields();
        toast.success("Password reset. We will redirect you to the login page now.", {
          position: "top-center",    
          onClose:() => {window.location.href="/"},
        })
        
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error('Please refresh the page and try again!', {
          position: "top-center",
        });
        setIsLoading(false);
      });
    }else{
      toast.error("Passwords don't match.", {
        position: "top-center",  
      })
    }    
  };

  return (
    <div className="signLog">
      {/* <div className="container"> */}
      <div className="signLog_container">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-8 col-md-6">
            <div className="header-wrapper d-flex align-items-center justify-content-between">
              <div className="brand d-flex align-items-center ">
                <img src={images.logo} alt="logo" className="brand_logo" />
              </div>
              <NavLink to="/login" className="signLog_link">
                Log In
              </NavLink>
            </div>

            <div className="signLog_wrapper">
              <div className="content-title">
                <h3>Password reset</h3>
                <span>
                  Already have an account?
                  <NavLink to="/login" className="signLog_link ms-2">
                    Log In
                  </NavLink>
                </span>
                </div>
                <div className="content-title">
              </div>
              {showResetForm?(
              <div>
                <div className="mb-3">
                  <label className="form-label">New Password</label>
                  <input
                    autoComplete="new-password"
                    type="password"
                    name="newPassword"
                    className="form-control"
                    placeholder="Enter your new password"
                    onChange={(e)=>{setNewPassword(e.target.value)}}
                  ></input>
                </div>
                <div className="mb-3">
                  <label className="form-label">Repeat New Password</label>
                  <input
                    type="password"
                    autoComplete="new-password"
                    name="newPasswordReset"
                    className="form-control"
                    placeholder="Repeat your new password"
                    onChange={(e)=>{setNewPasswordRepeat(e.target.value)}}
                  ></input>
                </div>
                {!isSamePasswords?(<div className="mb-3"><span>"Passwords don't match"</span></div>):(null)}
                <div className="mb-3">
                  <button
                    className="btn btn-primary w-100 btn-yellow"
                    type="submit"
                    name="submit"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    {isLoading ? (
                      <CircularProgress
                        style={{
                          width: "25px",
                          color: "white",
                          height: "25px",
                        }}
                      />
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </div>
              </div>):(null)}
            </div>
          </div>
          <div className="col-md-6 rightImg">
            <img src={images.LogBg} alt="" className="signLog_image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
