import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { images } from "../../../constants";
import DownSmall from "../../../assets/Icons/DownSmall.svg";
import ImageGallery from "./ImageGallery";
import ImageWizard from "./ImageWizard";
import ImageUploader from "../../ImageUploader/ImageUploader";
import RangeSlider from "../../RangeSlider/RangeSlider";
import { text2imgFromCat, img2imgFromCat } from "../../../redux/Thunks/Thunks";
//import wizardPresetsData from "./../../../utils/WizardPresetsData";
import "./CategoryDetails.scss";
import {getSingleImgUrl, getImgUrl} from "../../../firebase/Storage/Storage"
import {getPresets} from "../../../firebase/Setup/Setup"
import SizeSelector from "../../SizeSelector/SizeSelector";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

/**
 *@component Component to show category images preview, wizard , width, height and range selector
 * @ImageGallery give slider of image with preview
 * @ImageWizard it has wizard detail as Type promt
 * @ImageUpload use to upload images and also list all images
 * @RangeSlider give image selected range
 *
 * @param  {function} setSelectedCategory used to set category which is selected to get dynamic data
 * @param  {function}  generateHandler  function to generate images and generate alert
 * @param  {Object}  selectedCategory has all detail of selected category
 * @return  {Jsx}  It returns jsx for render a category details.
 *
 */

function CategoryDetails({
  wizardFormData,
  setWizardFormData,
  selectedFile,
  setSelectedFile,
  setSelectedCategory,
  selectedCategory,
  callbackHandler,
  setPrompt,
  isPrivate,
  inputValues,
  setInputValues
}) {
  const dispatch = useDispatch();
  const { loading, success, isError, errorMessage } =
    useSelector((state) => state.category2img) || {};

  const [isDisabled, setIsDisabled] = useState(true);
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [imageGeneratedAlert, setImageGeneratedAlert] = useState(false);
  const [step, setStep] = useState(1);
  const [wizardPresetsData, setWizardPresetData] = useState(null);
  const [imgUrl, setImgUrl] = useState("")
  const [exampleImagesUrls, setExampleImagesUrls] = useState([])
  const [width, setWidth] = useState(512);
  const [height, setHeight] = useState(512); //new search

  useEffect(() =>{
    getPresets().then((presets)=>{
      setWizardPresetData(presets.data)
    }).catch((err)=>{
      toast.error('Please refresh the page and try again!', {
        position: "top-center",
      });
      // console.log(err?.message);
    })
  }, [])


  useEffect(() => {
    if(selectedCategory?.trainingImages?.length){
      getSingleImgUrl(selectedCategory.mainImageUrl)
        .then((url) => {
          setImgUrl(url);
        })
        .catch((err) => {
          toast.error('Please refresh the page and try again!', {
            position: "top-center",
          });
        });
      }else
      {
        setImgUrl(selectedCategory.mainImageUrl)
      }
  }, [selectedCategory]);

  useEffect(() => {
    if(selectedCategory?.trainingImages?.length){
    getImgUrl(selectedCategory.trainingImages)
      .then((urls) => {
        setExampleImagesUrls(urls);
      })
      .catch((err) => {
        toast.error('Please refresh the page and try again!', {
          position: "top-center",
        });
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    const { prompt, width, height } = inputValues;
    if (prompt && width && height) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputValues]);

  useEffect(() => {
    if(success){
      setImageGeneratedAlert(true);
      setLoadingAlert(false);
    }
    if (isError) {
      toast.error(errorMessage, {
        position: "top-center",
      });
      setLoadingAlert(false);
    }
  }, [success, isError]);

  const handleSizeChange = (width, height) => {
    setInputValues((prevData) => {
      return {
        ...prevData,
        width: width,
        height: height,
      };
    });
  };
  const removeSelectedImage = (index) => {
    let arr = [...selectedFile];
    arr.splice(index, 1);
    setSelectedFile((current) => [...arr]);
    document.getElementById('file').value = ''
  };

  const onChangeHandle = (e) => {
    
    const { value, name, type } = e.target || {};

    setPrompt(value);

    setInputValues({
      ...inputValues,
      [name]: type === "number" ? parseInt(value) : value,
    });
  };

  const onGenerateHandle = (e) => {
    e.preventDefault()
    const allWizardPostScripts = wizardFormData
    .map(obj => obj.preset)
    .filter(preset => preset !=='')
    .join(',');

    const allWizardNegativePrompts = wizardFormData
    .map(obj => obj.presetNegativePrompt)
    .filter(presetNegativePrompt => presetNegativePrompt !=='')
    .join(',');
    
    //let finalPrompt = selectedCategory.postScript+" "+ inputValues.prompt;

    if(selectedFile?.length>0)
    {
       dispatch(img2imgFromCat({
        category_postscript: selectedCategory.postScript,
        prompt: inputValues.prompt,
        // model_id: null, //need to send category od
        model_id: selectedCategory?.model==="null"? selectedCategory.id: selectedCategory.model, //need to send category od
        negative_prompt: allWizardNegativePrompts, //no value getter in UI so sending hardcoded
        prompt_strength: ((inputValues.prompt_strength-1)/10).toFixed(1), //no value getter in UI so sending hardcoded,
        postscripts: allWizardPostScripts,
        width: inputValues.width,
        height: inputValues.height,
        samples: inputValues.samples,
        init_image:selectedFile[0]?.isUrl?selectedFile[0].path:selectedFile[0].file,
        init_image_name: selectedFile[0].name,
        init_image_is_url: selectedFile[0]?.isUrl?true:false,
        presetObject: JSON.stringify(wizardFormData)
      }))
    }else
    {
      dispatch(text2imgFromCat({
        category_postscript: selectedCategory.postScript,
        prompt: inputValues.prompt,
        // model_id: null, //need to send category od
        model_id: selectedCategory?.model==="null"? selectedCategory.id: selectedCategory.model, //need to send category od
        negative_prompt: allWizardNegativePrompts, //no value getter in UI so sending hardcoded
        width: inputValues.width,
        height: inputValues.height,
        samples: inputValues.samples,
        postscripts: allWizardPostScripts,
        presetObject: JSON.stringify(wizardFormData)
      }))
    }
    setWizardFormData([   
      { preset: "", presetNegativePrompt:"", step: 1 },
      { preset: "", presetNegativePrompt:"", step: 2 },
      { preset: "", presetNegativePrompt:"", step: 3 },
      { preset: "", presetNegativePrompt:"", step: 4 },
      { preset: "", presetNegativePrompt:"", step: 5 },
      { preset: "", presetNegativePrompt:"", step: 6 },
      { preset: "", presetNegativePrompt:"", step: 7 },
      { preset: "", presetNegativePrompt:"", step: 8 },
      { preset: "", presetNegativePrompt:"", step: 9 },
      { preset: "", presetNegativePrompt:"", step: 10 },
      { preset: "", presetNegativePrompt:"", step: 11 },
      { preset: "", presetNegativePrompt:"", step: 12 },
      { preset: "", presetNegativePrompt:"", step: 13 },
      { preset: "", presetNegativePrompt:"", step: 14 },
      { preset: "", presetNegativePrompt:"", step: 15 },
      { preset: "", presetNegativePrompt:"", step: 16 },
      { preset: "", presetNegativePrompt:"", step: 17 },
      { preset: "", presetNegativePrompt:"", step: 18 },
      { preset: "", presetNegativePrompt:"", step: 19 },
      { preset: "", presetNegativePrompt:"", step: 20 },
    ])
    setInputValues(({prompt: "", width: 512, height: 512, samples: 1, prompt_strength: 1}))
    callbackHandler();
    setWidth(512)
    setHeight(512)
  };

  return (
    <>
    {!wizardPresetsData?(null):(
    <div className="category_details_container">
      <div className="category_detail_wrapper">
        <div className="detail_header">
          <img
            src={DownSmall}
            alt=""
            className="back_btn"
            onClick={() => setSelectedCategory({})}
          />
          <div className="detail_header_title">
            {selectedCategory?.name}
          </div>
        </div>
        <div className="detail_header_txt">
          {selectedCategory?.description}
        </div>
        <ImageGallery image={imgUrl} exampleImages={isPrivate?exampleImagesUrls:null}/>
        <ImageWizard 
          step={step}
          setStep={setStep}
          promptFormData={inputValues}
          handlePromptData={onChangeHandle}
          setWizardFormData={setWizardFormData}
          wizardFormData={wizardFormData}
          presetsData={wizardPresetsData.presets}
          toShowsData={wizardFormData[step - 1]}
          setInputValues={setInputValues}
          categoryPostscript={selectedCategory.postScript}
        />
        <div className="image_size">
        <div className="title"> Optional - Upload A Reference Image</div>
        <div className="description" style={{paddingBottom:10}}>Upload a reference to create more images if you want. You can leave this blank.</div>
        <ImageUploader
          image={images.ImageUploadIcon}
          text="Drag an image here or"
          uploadFileText="upload a file"
          onFileSelectSuccess={(file) => {
            let reader = new FileReader();
            reader.onload = function (e) {
              let pngBase64Prefix = 'data:image/png;base64,';
              let imageData = e.target.result;
              let fileName = file.name
              if(imageData.includes(';base64,')) {
                imageData = pngBase64Prefix + imageData.split(';base64,')[1];
                fileName = fileName.split('.')[0] + '.png';
              }
              // setSelectedFile((current) => [...current, imageData]);
              setSelectedFile((current) => [
                { file: imageData, name: fileName },
              ]);
            };
            reader.readAsDataURL(file);
          }}
        />
        <div className="swiper-slider-wrapper">
                <Swiper
                  className="swiperSlider"
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={10}
                  slidesPerView={5}
                  navigation
                  // onSwiper={(swiper) => console.log(swiper)}
                  // onSlideChange={() => console.log("slide change")}
                >
                  {selectedFile.map((item, index) => {
                    return (
                      <div key={index}>
                        <SwiperSlide>
                          <div className="swiperSlider-item">
                            <img
                              src={item.file}
                              alt=""
                              className="swiperSlider-image"
                            />
                            <button
                              onClick={() => removeSelectedImage(index)}
                              className="swiper-slider-close-icon"
                              type="button"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </SwiperSlide>
                      </div>
                    );
                  })}
                </Swiper>
              </div>
        </div>

        {/* Image size module */}
        {selectedFile?.length>0? (null):(
        <div className="image_size">
          <div className="title">Size of image</div>
          <div className="description">
            Select the format this image will be created in
          </div>

          <SizeSelector handleSizeChange={handleSizeChange} width={width} height={height} setWidth={setWidth} setHeight={setHeight}/>


        </div>)}

        {/* Image Quantity slider */}
        {!selectedFile?.length>0? (null):(
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="form-title">
                      <h4>How wild do you want the AI to go?</h4>
                      <span className="sub-title">
                        Decide how “wild” the image generation process should be
                        from 1 to 10 - destruction of the image.
                      </span>
                    </div>
                  </div>
                  <div className="form-group mt-3 ">
                    <div className="field d-flex align-items-center justify-content-center">
                      <span className="value left">1</span>
                      <RangeSlider
                        max={10}
                        name="prompt_strength"
                        labelSwitch="auto"
                        value={inputValues.prompt_strength}
                        handlePromptData={onChangeHandle}
                      />
                      <span className="value right">10</span>
                    </div>
                  </div>
                </div>)}
        <div className="quantity_slider">
          <div className="title">Quantity</div>
          <div className="description">The number of image to generate</div>

          {/* Range Slider */}
          <div className="form-group mt-3 ">
            <div className="field d-flex align-items-center justify-content-center">
              <span className="value left">1</span>
              <RangeSlider
                max={3}
                name="samples"
                value={inputValues.samples}
                handlePromptData={onChangeHandle}
              />
              <span className="value right">3</span>
            </div>
          </div>
        </div>

        {/* Generate images button */}
        <div className="generator">
          <button
            className="btn btn-primary ms-2 mb-2 btn-yellow"
            onClick={onGenerateHandle}
           disabled={isDisabled}
          >
            Generate
          </button>
        </div>
      </div>
    </div>
    )}</>
  );
}

export default CategoryDetails;
