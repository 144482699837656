/**
 * @component    Component to edit name of user profile
 */
import { reload, updateEmail, updateProfile } from "firebase/auth";
import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../firebase/Authentication/context";
import { editEmailAddress, editName } from "../../redux/Thunks/Thunks";
import "./EditProfilePopup.scss";
import { toast } from "react-toastify";
import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from "firebase/auth";

// Component for showing popup edit user name

const EditProfilePopup = ({
  show,
  setShow,
  modalInfo,
  inputValues,
  setInputValues,
  callback,
}) => {
  const {user} = useContext(AuthContext);
  const [isError, setIsError] = useState(false);

  const clearAllFields = ()=>{
    setInputValues({email:"", name:"", newEmail: ""});
  }

  const inputChangeHandle = (e) => {
    const { name, value } = e.target;
    setInputValues((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleClose = () => {
    setShow(false);
    setIsError(false);
  };

  const handleSave = () => {
    if(user) {
      const { name, email, password, newEmail } = inputValues;
      if (name && email) {
        setShow(false);
        setIsError(false);
      } else {
        setIsError(true);
      }

      if (modalInfo?.title === "Edit Email") {
        if (newEmail) {
          setShow(false);
          setIsError(false);
        } else {
          setIsError(true);
        }
        const credential = getEmailAndPasswordCredential(email, password);
        reauthenticateWithCredential(user, credential)
          .then(() => {
            updateEmail(user, newEmail)
              .then(() => {
                toast.success("User email updated successfully", {
                  position: "top-center",
                });
                clearAllFields();
              })
              .catch((error) => {
                toast.error("Could not update user's email. Please try again.", {
                  position: "top-center",
                });
                
                setIsError(true);
              });
          })
          .catch((error) => {
            toast.error("Wrong credentials provided", {
              position: "top-center",
            });
            setIsError(true);
          });
      } else {
        updateProfile(user,{displayName: name}).then(()=>{
          toast.success("User name updated successfully", {
            position: "top-center",
          });
          
        }).catch((err)=>{
          toast.error("Could not update user name. Try again.", {
            position: "top-center",
          });
        })
      }
    }
  };

  
  const getEmailAndPasswordCredential = (email, password) => {
    return EmailAuthProvider.credential(email, password);
  };


  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <div className="row d-flex justify-content-center mod">
          <div className="col-md-12 modal_wrapper">
            <Modal.Header style={{ border: "none" }} closeButton>
              <Modal.Title>
                <div className="content-title">
                  <h3>{modalInfo?.title}</h3>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="edit-popup-body">
              {modalInfo?.title === "Edit Email" ? (
                <>
                  <input
                    type="email"
                    className={isError ? "input_error" : ""}
                    aria-label="Enter name"
                    id="value"
                    name="email"
                    value={inputValues.email}
                    onChange={inputChangeHandle}
                    placeholder="Enter Email"
                  />
                  {isError && <p>Required field</p>}
                  <input
                    type="password"
                    aria-label="Enter password"
                    id="password"
                    name="password"
                    placeholder="Enter password to confirm"
                    onChange={inputChangeHandle}
                  />
                  {isError && <p>Required field</p>}
                  <input
                    type="email"
                    className={isError ? "input_error" : ""}
                    aria-label="Enter name"
                    id="newEalue"
                    name="newEmail"
                    value={inputValues.newEmail}
                    onChange={inputChangeHandle}
                    placeholder="Enter New Email"
                  />
                  {isError && <p>Required field</p>}
                </>
              ) : (
                <>
                  <input
                    type="text"
                    className={isError ? "input_error" : ""}
                    aria-label="Enter name"
                    id="value"
                    name="name"
                    value={inputValues.name}
                    onChange={inputChangeHandle}
                    placeholder="Enter Name"
                  />
                  {isError && <p>Required field</p>}
                </>
              )}
            </Modal.Body>
            <Modal.Footer
              style={{
                border: "none",
                marginBottom: "15px",
                paddingTop: "0px",
              }}
            >
              <button className=" btn btn-goback" onClick={handleClose}>
                Cancel
              </button>

              <button className="btn btn-primary btn-yellow" onClick={handleSave}>
                Save
              </button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditProfilePopup;
