import React, { useRef } from "react";
import "./ImageUploader.scss";

/**
 *@component Component to upload new image from your device
 *
 * @param  {String} text image uploader text
 * @param  {String}  image  image uploader icon
 * @param  {String}  description image uploader file upload button text
 * @return  {Jsx}  It returns jsx for render a image uploader to upload image from your device.
 *
 */

const ImageUploader = ({
  image,
  text,
  uploadFileText,
  onFileSelectSuccess,
}) => {
  const fileInput = useRef(null);

const handleFileInput = (e) => {
  const file = e.target.files[0];
  const img = new Image();
  img.src = URL.createObjectURL(file);
  img.onload = () => {
    const { width, height } = img;
    const isWidthValid = width <= 1000 && width % 8 === 0;
    const isHeightValid = height <= 1000 && height % 8 === 0;
    if (isWidthValid && isHeightValid) {
      onFileSelectSuccess(file);
    } else {
      alert("Invalid image resolution. Please upload an image with maximum resolution of 1000x768 or 768x1000. Make sure that both width and height are multiples of 8.");
    }
  };
};
  return (
    <div>
      <div className="upload-file-background" useRef={fileInput}>
        <img src={image} alt="newImage" />
        <div className="upload-file-wrapper">
          <p>{text}</p>
          <input
            type="file"
            name="file"
            id="file"
            className="inputfile"
            onChange={handleFileInput}
          />
          <label className="input-label" htmlFor="file">
            {uploadFileText}
          </label>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
