import { createSlice } from "@reduxjs/toolkit";
import {
  changePassword,
  editEmailAddress,
  editName,
  getUserInformation,
} from "../Thunks/Thunks";

const initialState = {
  accountData: [],
  success: "",
  loading: false,
};

const accountSlice = createSlice({
  name: "Account",
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getUserInformation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserInformation.fulfilled, (state, action) => {
        state.loading = true;
        state.accountData = action?.payload;
      })
      .addCase(getUserInformation.rejected, (state, action) => {
        state.loading = true;
      })

      .addCase(editName.pending, (state) => {
        state.loading = true;
      })
      .addCase(editName.fulfilled, (state, action) => {
        state.loading = true;
        state.accountData = {...(state.accountData), ...(action?.payload)};
      })
      .addCase(editName.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload.message;
      })

      .addCase(editEmailAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(editEmailAddress.fulfilled, (state, action) => {
        state.loading = true;
        state.accountData = {...(state.accountData), ...(action?.payload)};
      })
      .addCase(editEmailAddress.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload.message;
      })

      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = true;
        state.success = action?.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload.message;
      });
  },
});

const { reducer } = accountSlice;
export default reducer;
