import React, { useEffect, useState, useContext} from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { images } from "../../constants";
import "./TopNav.scss";
import BaseModal from "../BaseModal/BaseModal";
import moment from "moment";
import { PopupModalsNav } from "./PopupModals/PopupModalsNav";
import { getUserInformation } from "./../../redux/Thunks/Thunks";
import { getAuth, reload } from "firebase/auth";
import { logout } from "./../../firebase/Authentication/Authentication"
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../firebase/Authentication/context";

const TopNav = ({ activeIndex }) => {
  const { user } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLogout = async () => {
    await logout();
    localStorage.clear()
    window.location.href = '/';
  }

 

  return (
    <div className="top__nav ">
      <div className="pageNavigation__wrapper mb-0">
        {/* <i className="ri-arrow-left-line me-3"></i> */}
        <h6>{activeIndex.name}</h6>
      </div>
      <div className="profile d-flex align-items-center">

        <h6 className="profile_name me-3">{user?user.displayName:""}</h6>
        <div className="logout_container">
          <NavLink className='userAvatar' to="user-profile">
            <img src={images.People} alt="" />
          </NavLink>
          <button id="logout" className="btn btn-secondary" onClick={handleLogout}>
            Log out
          </button>
        </div>
      </div>

      {/*daysLeft === 0 && (
        <PopupModalsNav show={show} handleClose={handleClose} />
      )*/}
      
    </div>
  );
};

export default TopNav;
