import React, { useState, useEffect, useId, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { text2Img } from "../../redux/Thunks/Thunks";
import moment from "moment/moment";

import { images } from "../../constants";
import "./TypePrompt.scss";
import Loader from "./../../components/Loader/Loader";

// import required modules
import PreviousSearch from "../../components/PreviousSearch/PreviousSearch";
import PendingSearch from "../../components/PendingSearches/PendingSearch";
import TypeaPromptNewSearches from "../../components/TypeaPromptNewSearches/TypeaPromptNewSearches";
import Tabs from "../../components/Tabs/Tabs";
import WizardPopup from "../../components/WizardPopup/WizardPopup";
import { toast } from "react-toastify";
import PendingAlertNotification from "../../components/AlertNotification/PendingAlertNotification";
import GenerateImages from "../../components/GenerateImages/GenerateImages";
import { db, getPresets } from "./../../firebase/Setup/Setup";
import { AuthContext } from "../../firebase/Authentication/context";

import { Navigate } from "react-router-dom";
import LogIn from "../LogIn/LogIn";


const { collection, onSnapshot } = require("firebase/firestore");

const TypePrompt = ({activeTab, setActiveTab, generatedImage, setGeneratedImage, showResult, setShowResult}) => {
  const {user, isTM, parentAccount} =useContext(AuthContext);
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [searchesData, setSearchesData] = useState([])
  const [resultData, setResultData] = useState({});
  const [initialResultData, setInitialResultData] = useState({});
  const { loading, aiDataText2Img } = useSelector((state) => state.typeAPrompt);
  //tabs
  const [showDot, setShowDot] = useState("");
  const promptInitialState = {
    prompt: "",
    wizard: [],
    width: "512",
    height: "512",
    prompt_strength: "1",
    samples: "1",
    enhance: "Disabled",
  };
  const [width, setWidth] = useState(promptInitialState.width);
  const [height, setHeight] = useState(promptInitialState.height); //new search
  const [promptFormData, setPromptFormData] = useState(promptInitialState);

  // wizard - toggle on off
  const [isOpen, setIsOpen] = useState(false);

  // wizard - slider - multi-step slider
  const [step, setStep] = useState(1);
  const [wizardPresetsData, setWizardPresetData] = useState(null);
  useEffect(() =>{
    getPresets().then((presets)=>{
      setWizardPresetData(presets.data)
    }).catch((err)=>{
      toast.error('Please refresh the page and try again!', {
        position: "top-center",
      });
    })
  }, [])

  useEffect(() => {
    setPromptFormData((prevData) => {
      return {
        ...prevData,
        "prompt": initialResultData?.prompt ?? '',
        "samples": initialResultData?.samples ?? 1,
        "width": initialResultData?.width ?? 512,
        "height": initialResultData?.height ?? 512,
      };
    });
    if(initialResultData?.presetObject?.length)
      setWizardFormData(JSON.parse(initialResultData.presetObject))
  }, [initialResultData])
  // wizard - form data
  const [wizardFormData, setWizardFormData] = useState([   
    { preset: "", presetNegativePrompt:"", step: 1 },
    { preset: "", presetNegativePrompt:"", step: 2 },
    { preset: "", presetNegativePrompt:"", step: 3 },
    { preset: "", presetNegativePrompt:"", step: 4 },
    { preset: "", presetNegativePrompt:"", step: 5 },
    { preset: "", presetNegativePrompt:"", step: 6 },
    { preset: "", presetNegativePrompt:"", step: 7 },
    { preset: "", presetNegativePrompt:"", step: 8 },
    { preset: "", presetNegativePrompt:"", step: 9 },
    { preset: "", presetNegativePrompt:"", step: 10 },
    { preset: "", presetNegativePrompt:"", step: 11 },
    { preset: "", presetNegativePrompt:"", step: 12 },
    { preset: "", presetNegativePrompt:"", step: 13 },
    { preset: "", presetNegativePrompt:"", step: 14 },
    { preset: "", presetNegativePrompt:"", step: 15 },
    { preset: "", presetNegativePrompt:"", step: 16 },
    { preset: "", presetNegativePrompt:"", step: 17 },
    { preset: "", presetNegativePrompt:"", step: 18 },
    { preset: "", presetNegativePrompt:"", step: 19 },
    { preset: "", presetNegativePrompt:"", step: 20 },
  ]);

  // table data for previous search
  const [pendingTableData, setPendingTableData] = useState([]);

  // table data for pending search
  const [prevSearchData, setPrevSearchData] = useState([]);

  // show generated image result for previous search
  // const [showResult, setShowResult] = useState(props?.showResult ? props.showResult : false);
  // const [activeTab, setActiveTab] = useState(props ? .tabItem ? props.tabItem : "newSearches");
  // const [generatedImage, setGeneratedImage] = useState(props?.generatedImage.length ? props.generatedImage : []);

  //----------functions----------

  // tabs switch with dot
  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    setShowResult(false);
    setShowDot("");
  };

  // handled text to image data
  const handlePromptData = (e) => {
    const { name, value } = e.target;
    setPromptFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };


  const handleSizeChange = (width, height) => {
      setPromptFormData((prevData) => {
        return {
          ...prevData,
          width: width,
          height: height,
        };
      });
    };

  // wizard - finish
  const handleFinish = () => {
    const wizard = wizardFormData?.map((data) => data.preset);
    setPromptFormData((prevData) => {
      return {
        ...prevData,
        wizard,
      };
    });
    toggleWizard();
    setStep(1);
  };

  // when click on generate this function will run
  const generateHandler = (e) => {
    e.preventDefault();
    setShowDot("pending");
    toast(<PendingAlertNotification />, {
      autoClose: 3000,
      hideProgressBar: true,
      className: "pending-alert",
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    const allWizardPostScripts = wizardFormData
    .map(obj => obj.preset)
    .filter(preset => preset !=='')
    .join(',');

    const allWizardNegativePrompts = wizardFormData
    .map(obj => obj.presetNegativePrompt)
    .filter(presetNegativePrompt => presetNegativePrompt !=='')
    .join(',');

    dispatch(
      text2Img({
        prompt: promptFormData.prompt,
        negative_prompt: allWizardNegativePrompts,
        width: promptFormData.width,
        height: promptFormData.height,
        samples: promptFormData.samples,
        presetObject: JSON.stringify(wizardFormData),
        postscripts: allWizardPostScripts
      })
    );

    const createDate = new Date();
    const formateDate = `${moment(createDate).format("LT")}, ${moment(
      createDate
    ).format("l")}`;

    setPendingTableData((prevData) => {
      return [
        ...prevData,
        {
          prompt: promptFormData.prompt,
          date: formateDate,
          pending: loading,
          imgData: aiDataText2Img,
        },
      ];
    });
    setWizardFormData([   
      { preset: "", presetNegativePrompt:"", step: 1 },
      { preset: "", presetNegativePrompt:"", step: 2 },
      { preset: "", presetNegativePrompt:"", step: 3 },
      { preset: "", presetNegativePrompt:"", step: 4 },
      { preset: "", presetNegativePrompt:"", step: 5 },
      { preset: "", presetNegativePrompt:"", step: 6 },
      { preset: "", presetNegativePrompt:"", step: 7 },
      { preset: "", presetNegativePrompt:"", step: 8 },
      { preset: "", presetNegativePrompt:"", step: 9 },
      { preset: "", presetNegativePrompt:"", step: 10 },
    ])
    setPromptFormData(promptInitialState);
    setWidth(promptInitialState.width)
    setHeight(promptInitialState.height)
  };

  // wizard toggle on off
  const toggleWizard = (e) => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    if(e!==undefined)
      e.preventDefault();
  };

  //view result of generated image
  const handleResult = () => {
    setShowResult(true);
  };

  useEffect(() => {
    let unsubscribe;
    if(user)
    {
      const uid = isTM?parentAccount:user.uid;
      unsubscribe=onSnapshot(
        collection(db, `Users/${uid}/generations`), 
        (snapshot) => {
          let userDocs = [];
          snapshot.docs.map(doc => {
            userDocs.push(doc.data())
          })
          setSearchesData(userDocs)
        },
        (error) => {
          // console.log('error', error)
        }
      )
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user])

  useEffect(() => {
    setPendingTableData(searchesData.filter(data => data.isDone === false))
    setPrevSearchData(searchesData.filter(data => data.isDone === true))
  }, [searchesData])

  return (
    <>
    {!user?(null):(
      wizardPresetsData ? <>
      <Tabs
        onClickTabItem={onClickTabItem}
        activeTab={activeTab}
        showDot={showDot}
      />
      <div className="tab-content">
        {activeTab === "newSearches" && (
          <TypeaPromptNewSearches
            generateHandler={generateHandler}
            handlePromptData={handlePromptData}
            handleSizeChange={handleSizeChange}
            promptFormData={promptFormData}
            toggleWizard={toggleWizard}
            width={width}
            height={height}
            setWidth={setWidth}
            setHeight={setHeight}
          />
        )}
        {activeTab === "pendingSearches" && (
          <PendingSearch
            tableData={pendingTableData}
            icon={images.TextPromptAvatar}
          />
        )}
        {activeTab === "previousSearches" && (
          <PreviousSearch
            icon={images.TextPromptAvatar}
            generatedImage={generatedImage}
            setGeneratedImage={setGeneratedImage}
            showResult={showResult}
            setResultData={setResultData}
            tableData={prevSearchData}
            handleResult={handleResult}
          />
        )}
      </div>

      {isOpen && (
        <WizardPopup
          step={step}
          setStep={setStep}
          handleFinish={handleFinish}
          promptFormData={promptFormData}
          handlePromptData={handlePromptData}
          setWizardFormData={setWizardFormData}
          wizardFormData={wizardFormData}
          toggleWizard={toggleWizard}
          presetsData={wizardPresetsData.presets}
          toShowsData={wizardFormData[step - 1]}
        />
      )}

      {(showResult && activeTab === "previousSearches") && (
        <GenerateImages
          resultData={resultData}
          setInitialResultData={setInitialResultData}
          generatedImage={generatedImage}
          setGeneratedImage={setGeneratedImage}
          handleResult={handleResult}
          onClickTabItem={onClickTabItem}
        />
      )}
    </> :  <Loader />)}
    </>
  );
};

export default TypePrompt;
