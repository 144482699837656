import React, { useContext, useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";

import SavedImageCard from "../../components/SavedImageCard/SavedImageCard";

import { images } from "../../constants";
import Pagination from "../../components/Pagination/Pagination";
import Loader from "../../components/Loader/Loader";
import "./SavedImages.scss";
import { AuthContext } from "../../firebase/Authentication/context";
import { Navigate } from "react-router-dom";
import LogIn from "../LogIn/LogIn";
import { getUserSavedImages } from "../../firebase/Setup/Setup";
import { getImgUrlAndPath } from "../../firebase/Storage/Storage";
import { db } from "./../../firebase/Setup/Setup";
import { toast } from "react-toastify";
const { collection, onSnapshot, doc } = require("firebase/firestore");

const ITEM_PER_PAGE = 10;
const TYPES = [
  "All time",
  "Today",
  "This past week",
  "This past month",
  "This past year",
  // "Custom",
];
/**
 * Page to preview the Images that are saved
 *
 * @component    Saved images
 */

const SavedImages = () => {
  const {user} = useContext(AuthContext);
  const [savedImages, setSavedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [total, setTotal] = useState(0);

  const [activePage, setActivePage] = useState(1);
  const [inputValues, setInputValues] = useState({ type: TYPES[0] });
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value)
  };
  
  async function fetchImages(){
    const savedImagesArr = await getUserSavedImages({}).then(async (response)=>{
      let resArr = [];
      if (response.data.savedImages) {
        const promises = response.data.savedImages.map(async (item) => {
          return await getImgUrlAndPath([item.path])
        })
        await Promise.all(promises).then((results) => {
          results.forEach((result, index) => {
            //let newDate = response.data.savedImages[index]?.date ? (`${new Date(response.data.savedImages[index].date._seconds * 1000).getFullYear()}-${new Date(response.data.savedImages[index].date._seconds * 1000).getMonth()+1}-${new Date(response.data.savedImages[index].date._seconds * 1000).getDate()}`) : ''
            resArr.push({linkData: result[0], date: response.data.savedImages[index]?.date})
          });
        })
        return resArr ?? null;
      }
    });
    if(savedImagesArr?.length>0)
    {
      setTotal(savedImagesArr.length);
      setSavedImages(savedImagesArr);
      setLoading(false);      
    }
  }

  useEffect(() => {
    let unsubscribe;
    if (user) {
      const userRef = doc(db, "Users", user.uid);
      unsubscribe = onSnapshot(
        userRef, 
        (doc) => {
          fetchImages()
        },
        (error) => {
          toast.error('Please refresh the page and try again!', {
            position: "top-center",
          });
        }
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user])

  useEffect(() => {
    let startDate = "";
    let endDate = "";
    switch (inputValues?.type) {
      case 'Today':
        startDate = `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`
        endDate = `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`
        break;
      case 'This past week':
        startDate = `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate() - 7}`
        endDate = `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`
        break;
      case 'This past month':
        startDate = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
        endDate = `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`
        break;
      case 'This past year':
        startDate = `${new Date().getFullYear() - 1}-${new Date().getMonth()+1}-${new Date().getDate()}`
        endDate = `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`
    }
    fetchImages();
  }, [activePage, inputValues]);

  const onChangeHandle = (e) => {
    const { value, name } = e?.target ?? {};
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const isSelected = (value) => inputValues?.type === value;

  return (
    <>
    {!user?(null):(
      <div className="saveImages">
        <div className="dashboard-heading-dropdown d-flex align-items-center justify-content-between flex-wrap">
          <div className="content-title">
            <div className="form-control">
              <img src={images.SearchIcon1} />
              <input
                className="ms-1 search-input"
                placeholder="Search"
                name="keyword"
                onChange={onChangeHandle}
                onBlur={() => setKeyword(inputValues.keyword)}
              />
            </div>
          </div>
          <div className="dashboard-timeframe-dropdown">
            <Form.Select name="type" onChange={onChangeHandle}>
              {TYPES.map((type) => {
                return (
                  <option value={type} selected={isSelected(type)}>
                    {`${isSelected(type) ? "Timeframe: " : " "} ${type}`}
                  </option>
                );
              })}
            </Form.Select>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : savedImages.length > 0 ? (
          <>
            <div className="row">
              {[...savedImages].sort((a, b) => b?.date?._seconds - a?.date?._seconds).slice((page - 1) * 8, page * 8).map((item, index) => {
                return (
                  <div
                    className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-6"
                    key={index}
                  >
                    <SavedImageCard
                      url={item.linkData.url}
                      path={item.linkData.path}
                      imageTitle={item.title}
                      // date={getDateFromFirebaseTimestamp(item.date)}
                    />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="d-flex flex-column empty justify-content-center align-items-center">
            <img src={images.NoImageIcon} alt="noImages" />
            <p>No Images</p>
          </div>
        )}
        {savedImages.length > 0 && (
          <div className="container d-flex justify-content-center">
            <Pagination count={Math.ceil(total/8)} page={page} onChange={handleChange} />
          </div>
        )}
      </div>)}
    </>
  );
};

export default SavedImages;
