/* eslint-disable react-hooks/exhaustive-deps */
import { Link, NavLink } from "react-router-dom";
import { images } from "../../constants";
import "./Footer.scss";

const Footer = ({ activeIndex, setActiveIndex, routes, children }) => {
  return (
    <div className="footer-container d-flex justify-content-between align-items-center">
      <div className="footer-buttons">
        {/* <a target="_blank" href="https://www.pixelbomb.gg/tos">Knowledge Base</a>
        <a target="_blank" href="https://www.pixelbomb.gg/privacy">Upgrade Plan</a> */}
      </div>
      <div className="footer-buttons">
        <a target="_blank" href="https://www.pixelbomb.gg/tos">Terms of Service</a>
        <a target="_blank" href="https://www.pixelbomb.gg/privacy">Privacy Policy</a>
        <a target="_blank" href="https://dazzlelabs.zohodesk.in/portal/en/home">Support Desk</a>
      </div>
    </div>
  );
};

export default Footer;
