import React from "react";

const Tabs = ({ onClickTabItem, activeTab, showDot }) => {
  return (
    <div className="tabs">
      <button
        className={`tab-item ${activeTab === "newSearches" && "active"}`}
        onClick={() => onClickTabItem("newSearches")}
      >
        New Search{" "}
      </button>

      <button
        className={`tab-item ${activeTab === "pendingSearches" && "active"}`}
        onClick={() => onClickTabItem("pendingSearches")}
      >
        Pending Searches
        <span className={showDot === "pending" ? "dot" : ""}></span>
      </button>
      <button
        className={`tab-item ${activeTab === "previousSearches" && "active"}`}
        onClick={() => onClickTabItem("previousSearches")}
      >
        Previous Searches
        <span className={showDot === "previous" ? "dot" : ""}></span>
      </button>
    </div>
  );
};

export default Tabs;
