import React from "react";
import { images } from "../../constants";

const GeneratedAlertNotification = ({ handleView, imgData }) => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-between">
      <img src={images.Done} className="ps-1 pe-1" />
      <div className="pending-info">
        <h2>Your image was generated!</h2>
        <span>
          Your image was successfully generated. You can view image now.
        </span>
        <div className="d-flex justify-content-between mt-2">
          <button
            className="btn btn-goback ms-2"
            onClick={() => {
              handleView(imgData);
            }}
          >
            View Now
          </button>
          <button className="btn btn-goback ms-2">Later</button>
        </div>
      </div>
    </div>
  );
};

export default GeneratedAlertNotification;
