import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import CategoryCard from "../CategoryCard/CategoryCard";
import SearchFilter from "./SearchFilter";
import CategoryDetails from "./CategoryDetails/CategoryDetails";
import CustomCategory from "./CustomCategory/CustomCategory";
import { GenerateAlertNotification } from "../GenerateAlertNotification/GenerateAlertNotification";
import Loader from "../Loader/Loader";
import Edit from "../../assets/Icons/edit-fill.svg";
import { db } from "./../../firebase/Setup/Setup";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";


import { getAllCategories } from "../../redux/Thunks/Thunks";
import "./NewSearch.scss";
import { AuthContext } from "../../firebase/Authentication/context";
import { useBattery } from "react-use";
const { collection, onSnapshot } = require("firebase/firestore");
/**
 *@component Component to show new Search detail of Categories
 * @CategoryCard to show categor images with details
 * @NewSearchFilter provide filter to search  images
 * @CategoryDetails all categories functionalities
 * @GenerateAlertNotification generate alert function
 *
 * @param  {Array} categories it has all the categories
 * @return  {Jsx}  It returns jsx for render a New Search items.
 *
 */

function NewSearch({wizardFormData, setWizardFormData, setSelectedFile, selectedFile, callbackHandler,setPrompt, selectedCategory, setSelectedCategory, inputValues, setInputValues}) {
  const { user, isTM, parentAccount } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { loading, categories = [] } =
    useSelector((state) => state.categoryState) || {};

  const [activeCategory, setActiveCategory] = useState("available");
  const [type, setType] = useState('All')
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [imageGeneratedAlert, setImageGeneratedAlert] = useState(false);
  const [keyword, setKeyword] = useState("");
  
  useEffect(() => {
    dispatch(getAllCategories({type: type}));
  }, [type]);


  useEffect(() => {
    let unsubscribe;
    if(user){
      const uid = isTM?parentAccount:user.uid;

    unsubscribe=onSnapshot(
      collection(db, `Users/${uid}/Categories`), 
      (snapshot) => {
        dispatch(getAllCategories({type: type}));
      },
      (error) => {
        toast.error('Please refresh the page and try again!', {
          position: "top-center",
        });
        // console.log('error', error)
      }
    );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user])


  const subCategoryClickHandle = (type) => {
    setActiveCategory(type);
    setSelectedCategory({});
  };

  const generateHandler = (action=null) => {
    callbackHandler(action)
    subCategoryClickHandle("available")
  }

  return selectedCategory?.mainImageUrl && activeCategory === "available" ? (
    <CategoryDetails
      wizardFormData={wizardFormData}
      setWizardFormData={setWizardFormData}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      setSelectedCategory={setSelectedCategory}
      selectedCategory={selectedCategory}
      callbackHandler = {callbackHandler}
      setPrompt = {setPrompt}
      isPrivate = {selectedCategory?.trainingImages?.length>0}
      inputValues={inputValues}
      setInputValues={setInputValues}
    />
  ) : (
    <div>
      <div className="d-flex mt-4" style={{ marginLeft: "2.5rem" }}>
        <div
          onClick={() => subCategoryClickHandle("available")}
          className={`sub_category_tab ${
            activeCategory === "available" && "sub_category_active"
          }`}
        >
          Available Categories
        </div>
        <div
          onClick={() => subCategoryClickHandle("custom")}
          className={`create_custom_category sub_category_tab ${
            activeCategory === "custom" && "sub_category_active"
          }`}
        >
          Create custom category
        </div>
      </div>
      {activeCategory === "available" && (
        <div div className = "tab-content_wrapper" >
          <SearchFilter setType={setType} type={type} keyword={keyword} setKeyword={setKeyword} />
          {
            !loading ? <div className="row">
              {categories?.private && categories.private.filter(row => (row?.name?.toLowerCase().indexOf(keyword.toLowerCase() ?? "") != -1 || 
                row?.description?.toLowerCase().indexOf(keyword?.toLowerCase() ?? "") != -1)).sort((a, b) => b.createdAt._seconds - a.createdAt._seconds).map((item, index) => {
                return (
                  <div key={index} className="category_card_container col-md-6 col-sm-12 col-lg-3 mb-3 mb-md-4">
                    <div
                      key={item?.id}
                      onClick={() => {
                        // handleCategoryModel(index);
                        if(item.id !== "NULL")
                          setSelectedCategory(item);
                      }}
                    >
                      <CategoryCard
                        mainImageUrl={item?.mainImageUrl}
                        name={item?.name}
                        description={item?.description}
                        isPrivate={true}
                        id={item.id}
                        createdAt={item.createdAt}
                        eta={item.eta}
                        cid = {item.cid}
                      />
                    </div>

                    {/* <img
                      src={Edit}
                      alt=""
                      className="edit_fill_img"
                      onClick={() => {
                        setSelectedCategory(item);
                        setActiveCategory("custom");
                      }}
                    /> */}
                  </div>
                );
              })}
              {categories?.public && categories.public.filter(row => (row.name.toLowerCase().indexOf(keyword.toLowerCase() ?? "") != -1 || 
                row.description.toLowerCase().indexOf(keyword.toLowerCase() ?? "") != -1)).sort((a, b) => b.createdAt._seconds - a.createdAt._seconds).map((item, index) => {
                return (
                  <div key={index} className="category_card_container col-md-6 col-sm-12 col-lg-3 mb-3 mb-md-4">
                    <div
                      key={item?.c_id}
                      onClick={() => {
                        // handleCategoryModel(index);
                        setSelectedCategory(item);
                      }}
                    >
                      <CategoryCard
                        mainImageUrl={item?.mainImageUrl}
                        name={item?.name}
                        description={item?.description}
                        isPrivate={false}
                        id={item.id}                        
                        createdAt={item.createdAt}
                        eta={0}
                        cid = {item.cid}

                      />
                    </div>
                  </div>
                );
              })}
            </div> : <CircularProgress style={{
              top: '50%',
              left: '50%',
              width: '40px',
              height: '40px',
              transform: 'translate(-100%, 50%)',
              position: 'absolute'
            }} />
          }
        </div>
      )}
      {activeCategory === "custom" && (
        <CustomCategory
          generateHandler={generateHandler}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
          subCategoryClickHandle={subCategoryClickHandle}
        />
      )}

      {/* Generator notification */}
      <GenerateAlertNotification
        imageGeneratedAlert={imageGeneratedAlert}
        setImageGeneratedAlert={setImageGeneratedAlert}
        loadingAlert={loadingAlert}
        setLoadingAlert={setLoadingAlert}
      />
    </div>
  );
}

export default NewSearch;
