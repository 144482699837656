import React, { useState } from "react";
import WizardPopup from "../../WizardPopup/WizardPopup";
import { images } from "../../../constants";
import "./CategoryDetails.scss";

/**
 *@component Component to show Wizard
 * @WizardPopup popup of wizard
 *
 * @return  {Jsx}  It returns jsx for render a show Wizard.
 */

function ImageWizard({
  step,
  setStep,
  setWizardFormData,
  presetsData,
  toShowsData,
  handlePromptData,
  promptFormData,
  wizardFormData,
  setInputValues,
  categoryPostscript
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleWizard = (e) => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    if (e!==undefined)
    {
      e.preventDefault();
    }
  };

  // wizard - finish
  const handleFinish = () => {
    const wizard = wizardFormData?.map((data) => data.preset);
    setInputValues((prevData) => {
      return {
        ...prevData,
        wizard,
      };
    });
    setStep(1);
    toggleWizard();
  };
  return (
    <>
      <div className="image_wizard_container">
        <div className="d-flex align-items-center justify-content-between">
          <div className="form-title">
            <h4 className="d-flex align-items-center justify-content-left">
              Describe the image you want to see
            </h4>
            <span className="sub-title">
            Include name of humans/objects used while creating the category, color, locations, people, landscape.
            </span>
          </div>
          <button className="btn btn-wizard" onClick={toggleWizard}>
            <img src={images.Magic} alt="" className="me-1" />
            Wizard
          </button>
        </div>
        <div className="form-group mt-3">     
          <textarea
            className="form-control"
            rows="4"
            name="prompt"
            placeholder={`What do you want to generate?`}
            value={promptFormData.prompt}
            onChange={handlePromptData}
          ></textarea>
          
        </div>
      </div>
      {isOpen && <WizardPopup step={step}
        setStep={setStep}
        handleFinish={handleFinish}
        promptFormData={promptFormData}
        handlePromptData={handlePromptData}
        setWizardFormData={setWizardFormData}
        wizardFormData={wizardFormData}
        toggleWizard={toggleWizard}
        presetsData={presetsData}
        toShowsData={toShowsData} />}
    </>
  );
}

export default ImageWizard;
