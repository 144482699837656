import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { createSubAccount } from "../../../../redux/Thunks/Thunks";

import "./SubAccount.scss";
import { SubAccPopupModal } from "./SubAccPopupModal/SubAccPopupModal";
import { SubAccTable } from "./SubAccTable/SubAccTable";
import { db, deleteTeamMember } from "../../../../firebase/Setup/Setup";
import { AuthContext } from "../../../../firebase/Authentication/context";
import { doc, updateDoc } from "firebase/firestore";
import { getDoc } from "firebase/firestore";
import { FieldValue } from "firebase/firestore";
import { createTeamMember } from "../../../../firebase/Setup/Setup";
import { toast } from "react-toastify";



const { collection, onSnapshot } = require("firebase/firestore");


const SubAccount = () => {
  const {user} = useContext(AuthContext);
  const [inputData, setInputData] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [haveClient, setHaveClient] = useState(false);
  const [haveTeamMember, setHaveTeamMember] = useState(false);
  //popup
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [isOpenOutSource, setIsOpenOutSource] = useState(false);
  const toggleClient = () => {
    setIsOpenClient(!isOpenClient);
  };
  const toggleOutsource = () => {
    setIsOpenOutSource(!isOpenOutSource);
  };
  const preventClose = (event) => {
    event.stopPropagation();
  };

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    setInputData({ type: tab });
  };

  const cleanAllFields=()=>{
    setInputData({});
  }

  const handleDeleteItem = async (id) => {
    if (user?.uid) {
      toast.info(`The user is being deleted`, {
        position: "top-center",  
      });
      const ret = await deleteTeamMember({uid: id});
      
      if(ret.data.status==200)
      {
        toast.success(`The user was deleted from your team.`, {
          position: "top-center",  
        });
      }else{
        toast.error(ret.data.message, {
          position: "top-center",  
        });
      }
    }
  };

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setInputData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };


  const [tableData, setTableData] = useState([]);
  

  useEffect(() => {
  let unsubscribe;
  const fetchSubAccounts = async () => {
    if (user?.uid) {
      const userRef = doc(db, "Users", user.uid);
      unsubscribe = onSnapshot(userRef, (doc) => {
        setHaveClient(doc.data().plan.isClient)
        setHaveTeamMember(doc.data().plan.isMember)
        if(doc.data().plan.isMember) {
          setActiveTab('outSourcers')
        }
        if(doc.data().plan.isClient) {
          setActiveTab('client')
        }
        const subAccounts = doc.data().subAccounts.map((subAccount) => {
          // Convert Firestore Timestamp to Date object
          return {
            email: subAccount.email,
            id: subAccount.id,
            name: subAccount.name,
            role: subAccount.role
          }
        });
        setTableData(subAccounts);
      });
    }
  };
  fetchSubAccounts();

  return () => {
    if (unsubscribe) {
      unsubscribe();
    }
  };
}, []);


const create = async (e) => {
  e.preventDefault();
  const { name, email } = inputData;
  if (name && email) {
    const role = isOpenClient? "CLI":"MEM";
    toast.info(`The user is being created`, {
      position: "top-center",  
    });
    const ret = await createTeamMember({name:name, email:email, role:role});
    
    if(ret.data.status==200)
    {
      toast.success(`${name}, was invited to your team.`, {
        position: "top-center",  
      });
    }else{
      toast.error(ret.data.message, {
        position: "top-center",  
      });
    }
  
    setIsOpenClient(false);
    setIsOpenOutSource(false);
    cleanAllFields();
  }
};



  return (
    <div className="subAccounts">
      {
        (haveClient || haveTeamMember) ? (
          <>
            <div className="subAccounts d-flex justify-content-between flex-wrap">
              <div className="tabs mb-2">
                {haveClient && <button
                  className={`tab-item d-flex  align-items-center ${
                    activeTab === "client" ? "active" : ""
                  }`}
                  onClick={() => onClickTabItem("client")}
                >
                  Clients
                </button>}
      
                {haveTeamMember && <button
                  className={`tab-item d-flex  align-items-center ${
                    activeTab === "outSourcers" ? "active" : ""
                  }`}
                  onClick={() => onClickTabItem("outSourcers")}
                >
                  Team members
                </button>}
              </div>
              {activeTab === "client" && (
                <button
                  className="d-flex btn btn-primary mb-2 align-items-center btn-yellow"
                  onClick={toggleClient}
                >
                  <i className="ri-add-fill me-2"></i>
                  Add new client
                </button>
              )}
              {activeTab === "outSourcers" && (
                <button
                  className="d-flex btn btn-primary mb-2 align-items-center btn-yellow"
                  onClick={toggleOutsource}
                >
                  <i className=" ri-add-fill me-2"></i>
                  Add new team member
                </button>
              )}
            </div>
            <div className="tab-content">
              {activeTab === "client" && (
                <SubAccTable
                  roleFilter="CLI"
                  tableData={tableData}
                  handleDeleteItem={handleDeleteItem}
                />
              )}
              
            </div>
            <div className="tab-content">
              {activeTab === "outSourcers" && (
                <SubAccTable
                  roleFilter="MEM"
                  tableData={tableData}
                  handleDeleteItem={handleDeleteItem}
                />
              )}
              
            </div>
      
            {isOpenClient && (
              <SubAccPopupModal
                toggle={toggleClient}
                preventClose={preventClose}
                inputData={inputData}
                handleInputData={handleInputData}
                create={create}
                text="Client"
              />
            )}
            {isOpenOutSource && (
              <SubAccPopupModal
                toggle={toggleOutsource}
                preventClose={preventClose}
                handleInputData={handleInputData}
                inputData={inputData}
                create={create}
                text="Team Member"
              />
            )}
          </>
        ) : <h5>This functionality is not available in your current plan.</h5>
      }
    </div>
  );
};

export default SubAccount;
