import React, { useContext, useEffect, useState } from "react";
import { images } from "../../../../constants";
import "./Profile.scss";
import EditProfilePopup from "../../../EditProfilePopup/EditProfilePopup";
import { AuthContext } from "../../../../firebase/Authentication/context";
import { getPlanLogData } from "../../../DashboardData/DashboardData";
import { current } from "@reduxjs/toolkit";


const Profile = () => {
  const {user, isTM, parentAccount, userDoc} = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const [inputValues, setInputValues] = useState({email: "", name:""});

  const [current, setCurrent] =useState({});
  const [previous, setPrevious] =useState({});


  // handel modal
  const handleModal = (info) => {
    setShow(true);
    setModalInfo({ title: `Edit ${info}`, placeholder: `Enter ${info}` });
  };

  useEffect(() => {
    if(user)
    {
      setInputValues({email: user.email, name: user.displayName});
    }
  }, [user]);

  useEffect(() => {
    if(user){
      const uid = isTM?parentAccount:user.uid;
      getPlanLogData(uid, "This past month", null, "monthly").then((planLogData)=>{
        const current = planLogData.current;
        const previous = planLogData.previous;
        // Calculate total searches, generated, and saved for current and previous periods
        setCurrent({
          searches: current.reduce((total, item) => total + item.Searches, 0),
          generated: current.reduce((total, item) => total + item.Generated, 0),
          saved: current.reduce((total, item) => total + item.Saved, 0),
          categories: current.reduce((total, item) => total + item.Categories, 0)
        });
        setPrevious({
          searches: previous.reduce((total, item) => total + item.Searches, 0),
          generated: previous.reduce((total, item) => total + item.Generated, 0),
          saved: previous.reduce((total, item) => total + item.Saved, 0),          
          categories: previous.reduce((total, item) => total + item.Categories, 0)
        });
      });
    }
  },[]);

  return (
    <>
    {!user?(null):(
      <div className="user-profile-general row">
        <div className="col-md-12">
          <div className="row">
            <div className="profileGeneral col-md-4 col-sm-12">
              <h6 className="sub-title">General</h6>
              <div className="profileGeneral-inner">
                <div className="profile_name d-flex justify-content-between">
                  <span>Name:</span>
                  <span className="userName text-start">
                    {user.displayName}
                  </span>
                  <button
                    className="editButton"
                    onClick={() => handleModal("Name")}
                  >
                    <img src={images.Edit} alt="" />
                  </button>
                </div>
                <div className="profile_email d-flex justify-content-between  mt-2">
                  <span>Email:</span>
                  <span className="userEmail text-start">
                    {user.email}
                  </span>
                  <button
                    className="editButton"
                    onClick={() => handleModal("Email")}
                  >
                    <img src={images.Edit} alt="email" />
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div className="imageStatistic col-md-10 col-sm-12">
              <h6 className="sub-title">Usage:</h6>
              <div className="imageStatistic-inner">
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <div className="imageStatistic-card">
                      <span>Searches</span>
                      <span>{current.searches?current.searches:"0"} out of {userDoc?.plan?.isUnlimitedSearch? "unlimited" : userDoc?.plan?.searches+"/ month"}</span>
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="imageStatistic-card">
                      <span>Generated</span>
                      <span>{current.generated?current.generated:"0"} out of {userDoc?.plan?.isUnlimitedGenerate? "unlimited" : userDoc?.plan?.generated+"/ month"} </span>
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="imageStatistic-card">
                      <span>Saved</span>
                      <span>{current.saved?current.saved:"0"} out of {userDoc?.plan?.isUnlimitedSave? "unlimited" : userDoc?.plan?.saved+"/ month"}</span>
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="imageStatistic-card">
                      <span>Custom categories</span>
                      <span>{userDoc?.plan?.isMonthlyCategory ? ((current.categories? current.categories: "0")+ ' out of ' + (userDoc?.plan?.isUnlimitedCategories ? "unlimited" : userDoc?.plan?.categories) + '/ month'): (current.categories? current.categories: "0")+"/"+(userDoc?.plan?.isUnlimitedCategories ? "unlimited" : userDoc?.plan?.categories) +" one time"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditProfilePopup
          show={show}
          setShow={setShow}
          inputValues={inputValues}
          setInputValues={setInputValues}
          modalInfo={modalInfo}
          
        />
      </div>
      )}
    </>
  );
};

export default Profile;
