import React from "react";

export const SubAccPopupModal = ({
  toggle,
  preventClose,
  text,
  inputData,
  handleInputData,
  create,
}) => {
  return (
    <div className="popup" onClick={preventClose}>
      <div className="popup_wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 bg-white p-4 rounded-2">
              <div className="popup_toogleButtons d-flex justify-content-between">
                <div className="content-title">
                  <h3>Add a {text}</h3>
                </div>
                <button className="btn btn-close " onClick={toggle}>
                  <i className="ri-close-line"></i>
                </button>
              </div>
              <form onSubmit={create}>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    name="name"
                    value={inputData.name}
                    onChange={handleInputData}
                    placeholder="User Name"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    className="form-control"
                    name="email"
                    value={inputData.email}
                    onChange={handleInputData}
                    placeholder="user@email.com"
                  />
                </div>
                {/* <div id="emailHelp" className="form-text mb-3">
                  Note: allow create up to 5 accounts.
                </div> */}
                <button className="btn btn-goback me-3" onClick={toggle}>
                  Cancel
                </button>
                <button className="btn btn-primary btn-yellow" type="submit">
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
