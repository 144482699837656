import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation } from "swiper";

import { images } from "../../constants";

import "./WizardPopup.scss";
import ConfirmModal from "./ConfirmModal";

/**
 *@component Component to show Wizard popup
 *
 * @param  {function} toggleWizard toggle to on or off state of Wizard popup
 *
 * @return  {Jsx}  It returns jsx for render a Wizard popup.
 *
 */

const WizardPopup = ({
  step,
  setStep,
  toggleWizard,
  setWizardFormData,
  wizardFormData,
  presetsData,
  toShowsData,
  handleFinish,
  handlePromptData,
  promptFormData
}) => {
  // wizard - slider - multi-step slider
  const [show, setShow] = useState(false);
  const [localSelection, setLocalSelection] =useState([   
    { preset: "", presetNegativePrompt:"", step: 1 },
    { preset: "", presetNegativePrompt:"", step: 2 },
    { preset: "", presetNegativePrompt:"", step: 3 },
    { preset: "", presetNegativePrompt:"", step: 4 },
    { preset: "", presetNegativePrompt:"", step: 5 },
    { preset: "", presetNegativePrompt:"", step: 6 },
    { preset: "", presetNegativePrompt:"", step: 7 },
    { preset: "", presetNegativePrompt:"", step: 8 },
    { preset: "", presetNegativePrompt:"", step: 9 },
    { preset: "", presetNegativePrompt:"", step: 10 },
    { preset: "", presetNegativePrompt:"", step: 11 },
    { preset: "", presetNegativePrompt:"", step: 12 },
    { preset: "", presetNegativePrompt:"", step: 13 },
    { preset: "", presetNegativePrompt:"", step: 14 },
    { preset: "", presetNegativePrompt:"", step: 15 },
    { preset: "", presetNegativePrompt:"", step: 16 },
    { preset: "", presetNegativePrompt:"", step: 17 },
    { preset: "", presetNegativePrompt:"", step: 18 },
    { preset: "", presetNegativePrompt:"", step: 19 },
    { preset: "", presetNegativePrompt:"", step: 20 },
  ]);

  const totalSteps = presetsData.length
  // wizard slider - multi-step slider
  const handleNext = () => {
    setStep(Math.min(step + 1, totalSteps));
  };

  const handlePrev = () => {
    setStep(Math.max(step - 1, 1));
  };

  const handleWizard = (e, pName) => {
    var { name, value, checked } = e.target;
    
    if(checked) value = ''
    // Re-render with the new array
    // setLocalSelection((prevData) =>
    //   prevData.map((data) => {
    //     if (data.step !== step) {
    //       // No change
    //       return data;
    //     } else {
    //       // Return a new
    //       let presetName=pName;
    //       if(checked) presetName = ""
    //       return {
    //         ...data,
    //         presetName
    //       };
    //     }
        
    //   })
    //   );
    setWizardFormData((prevData) =>
      prevData.map((data) => {
        if (data.step !== step) {
          // No change
          return data;
        } else {
          // Return a new
          return {
            ...data,
            [name]: value,
          };
        }
      })
    );
  };

  useEffect(() => {
    if (wizardFormData) {
      setLocalSelection((prevData) =>
        prevData.map((data, index) => {
          let presetName = '';
          presetsData?.map((presetData) => {
            presetData?.preset?.map(preset => {
              if(preset.postscript === wizardFormData[index]?.preset)
                presetName = preset.name
            })
          })
          return {
            ...data,
            presetName
          };
        })
      );
    }
  }, [wizardFormData])

  return (
    <>
      <div className="popup">
        <div className="popup_wrapper">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-7 subjectWizard_wrapper  border-bg">
                <div className="subjectWizard ">
                  <div className="d-flex justify-content-end">
                    <button className="btn p-0" onClick={() => {
                      toggleWizard()
                      setWizardFormData([   
                        { preset: "", presetNegativePrompt:"", step: 1 },
                        { preset: "", presetNegativePrompt:"", step: 2 },
                        { preset: "", presetNegativePrompt:"", step: 3 },
                        { preset: "", presetNegativePrompt:"", step: 4 },
                        { preset: "", presetNegativePrompt:"", step: 5 },
                        { preset: "", presetNegativePrompt:"", step: 6 },
                        { preset: "", presetNegativePrompt:"", step: 7 },
                        { preset: "", presetNegativePrompt:"", step: 8 },
                        { preset: "", presetNegativePrompt:"", step: 9 },
                        { preset: "", presetNegativePrompt:"", step: 10 },
                        { preset: "", presetNegativePrompt:"", step: 11 },
                        { preset: "", presetNegativePrompt:"", step: 12 },
                        { preset: "", presetNegativePrompt:"", step: 13 },
                        { preset: "", presetNegativePrompt:"", step: 14 },
                        { preset: "", presetNegativePrompt:"", step: 15 },
                        { preset: "", presetNegativePrompt:"", step: 16 },
                        { preset: "", presetNegativePrompt:"", step: 17 },
                        { preset: "", presetNegativePrompt:"", step: 18 },
                        { preset: "", presetNegativePrompt:"", step: 19 },
                        { preset: "", presetNegativePrompt:"", step: 20 },
                      ])
                      }}>
                      <img src={images.Close} alt="" />
                    </button>
                  </div>
                  <div className="content-title">
                    <h3>Wizard</h3>
                    <span>
                      This wizard can guide you through the process of selecting the appropriate preset and adjusting the various parameters to achieve the desired outcome with your images.
                      If a preset does not fit with your requirements, please don't choose it
                    </span>
                  </div>
                  <div className="mt-4 ">
                    <div className="title-selector border-bg">
                      <h4>{(presetsData[step - 1])?.title}</h4>
                      <span className="sub-title">{presetsData[step - 1]?.info}</span>
                    </div>
                    <div className="subjecWizard_slider">
                      <Swiper
                        slidesPerView={1}
                        grid={{
                          rows: 2,
                          fill: "row",
                        }}
                        spaceBetween={10}
                        navigation={true}
                        modules={[Grid, Navigation]}
                        className="mySwiper"
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                          },
                          1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                          },
                        }}
                      >
                        {presetsData[step - 1]?.preset?.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div
                                className={`sliderGrid-item ${
                                  toShowsData?.preset === item.postscript &&
                                  "selected-radio-btn"
                                }`}
                              >
                                <img
                                  src={item.imageUrl}
                                  alt=""
                                  className="item-image"
                                />
                                <div className="item-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="preset"
                                    value={item.postscript}
                                    checked={toShowsData?.preset === item.postscript}
                                    onMouseDown={(e)=>handleWizard(e, item.name)}
                                  ></input>
                                </div>
                                <div className="item-title">{item.name}</div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="mt-4 mb-2">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div className="container-dots">
                      {[...Array(totalSteps)].map((_, i) => (
                        <div
                          key={i}
                          className={`dot ${i + 1 === step ? "active" : ""}`}
                          onClick={() => setStep(i + 1)}
                        />
                      ))}
                    </div>
                    <div className="">
                      {step > 1 ? (
                        <button
                          className="btn btn-goback me-3"
                          onClick={handlePrev}
                          disabled={step === 1}
                        >
                          Back
                        </button>
                      ) : (
                        <div></div>
                      )}

                      {step === totalSteps ? (
                        <button
                          className="btn btn-primary btn-yellow"
                          onClick={() => {setShow(true)}}
                        >
                          Finish
                        </button>
                      ) : (

                        <button className="btn btn-primary btn-yellow" onClick={handleNext}>
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show?(<ConfirmModal show={show} setShow={setShow} handleFinish={handleFinish} presetsList={localSelection} />):(null)}
      
    </>
  );
};

export default WizardPopup;