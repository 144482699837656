import { CircularProgress } from "@mui/material";
import React from "react";

const PendingSearch = ({ tableData, icon }) => {
  return (
    <div className="tab-content_wrapper">
      <div className="table-responsive">
        <table className="table ">
          <thead>
            <tr>
              <th scope="col" style={{minWidth: '190px'}}>Created</th>
              <th scope="col">Prompt</th>
              <th scope="col" style={{minWidth: '150px'}}></th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.sort((a, b) => b?.updatedAt?.seconds - a?.updatedAt?.seconds).map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="table_createDate d-flex align-items-center">
                        <img src={icon} alt="" />
                        <div className="date ms-2">{item?.updatedAt ? `${new Date(item?.updatedAt.seconds*1000).getFullYear()}-${new Date(item?.updatedAt.seconds*1000).getMonth()+1}-${new Date(item?.updatedAt.seconds*1000).getDate()} ${new Date(item?.updatedAt.seconds*1000).getHours()}:${new Date(item?.updatedAt.seconds*1000).getMinutes()}:${new Date(item?.updatedAt.seconds*1000).getSeconds()}` : ''}</div>
                      </div>
                    </td>
                    <td>
                      <p>{item.prompt}</p>
                    </td>
                    <td>
                      <div className="table_processing d-flex align-items-center">
                      <CircularProgress size="1rem" style={{ marginRight: "0.5rem" }} color="warning"/>
                      <span style={{ marginLeft: "0.5rem" }}>Creating...</span>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className="no-data text-center">
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PendingSearch;
