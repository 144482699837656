import React, { useState, useEffect } from "react";
import "./GenerateImages.scss";
import GenerateImageCard from "../GenerateImageCard/GenerateImageCard";
import { images } from "../../constants";
import { useLocation } from "react-router-dom";
import { getImgUrl } from "../../firebase/Storage/Storage";
import CircularProgress from "@mui/material/CircularProgress";

const GenerateImages = ({ handleResult, resultData, setInitialResultData, generatedImage, onClickTabItem }) => {
  let { pathname } = useLocation();
  //fetching generate Images
  const [urls, setUrls] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const handelLoadMore = () => {
    setInitialResultData(resultData)
    onClickTabItem("newSearches")
  };

  function reloadHandler()
  {
    setReload(true)
  }

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {

    async function getUrls(){
     const urls = await getImgUrl(generatedImage)
     setUrls(urls);
    }
    getUrls();
  }, [reload])
  return (
    <>
    {
      loading ? <CircularProgress
        style={{
          top: '50%',
          left: '50%',
          width: '40px',
          height: '40px',
          position: 'absolute'
        }}
      /> : <div className="generateImages">
        <div className="content-title d-flex  align-items-center ">
          <img
            src={images.BackArowIcon}
            onClick={() => onClickTabItem("previousSearches")}
            alt="back arow icon"
          />
          <h3 className="mb-0 ms-3">Generated Images</h3>
        </div>
        <div className="description">
          AI Image generation is fairly new and it may take multiple attempts to generate something you really like.
          <br />We highly recommend you subscribe to our <a target="_blank" href="https://pixelbomb.gg/pro/">PRO plan</a> (if you haven't already)
          which will give you the power to search unlimited times, generate and save unlimited amount of images once you create your category.
        </div>
        <div className="row">
          {urls?.map((imgData, index) => {
            return (
              <div
                className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-6"
                key={index}
              >
                <GenerateImageCard mainImageUrl={imgData} path={generatedImage[index]} reloadHandler={reloadHandler} />
              </div>
            );
          })}
        </div>
        <div className="mt-4 d-flex justify-content-center">

          <button className="btn btn-primary" onClick={handelLoadMore}>
            <a className="btn-yellow">Generate More</a>

          </button>
        </div>
      </div>
    }
    </>
  );
};

export default GenerateImages;
