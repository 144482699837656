import React, { useState, useEffect } from "react";
import { Typography, Slider } from "@mui/material"
import { Box } from "@mui/material";


function SizeSelector({handleSizeChange, width, height, setWidth, setHeight}) {
  const handleWidthChange = (event, newValue) => {
    if (newValue > 768 && height >= 768) {
      setWidth(newValue);
      setHeight(768);
    } else {
      setWidth(newValue);
    }
  };

  const handleHeightChange = (event, newValue) => {
    if (newValue > 768 && width >= 768) {
      setHeight(newValue);
      setWidth(768);
    } else {
      setHeight(newValue);
    }

  };

  const handleValueText = (value) => {
    return `${value}px`;
  };

  const formatValue = (value) => {
    return Math.round(value / 8) * 8;
  };

  const valueLabelFormat = (value) => {
    return `${formatValue(value)}px`;
  };

  useEffect(()=>{
    
    handleSizeChange(width, height);

  },[width,height])

  return (
    <div className="form-group mt-3 size-section">
      <div className="field d-flex flex-column align-items-center justify-content-center">

        <Typography gutterBottom>Width</Typography>
        <Slider
          value={width || 512}
          onChange={handleWidthChange}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          min={256}
          max={1024}
          step={8}
          getAriaValueText={handleValueText}
          color="secondary"
          sx={{
            color: "#EBEBEB",
            htight: 4,
            "& .MuiSlider-thumb": {
                height: 20,
                width: 20,
                backgroundColor: "var(--bg-slider-yellow)",
                border: "2px solid var(--primary)",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&:before": {
                  display: "none",
                },
            },
            "& .MuiSlider-track": {
                border: "none",
                backgroundColor: "var(--bg-slider-yellow)",
              },
            "& .MuiSlider-valueLabel": {
                lineHeight: 1.2,
                fontSize: 12,
                background: "unset",
                padding: 0,
                width: 32,
                height: 32,
                backgroundColor: "var(--bg-slider-yellow)",
                color: "var(--primary)"
              },
          }}
        />
        <Typography gutterBottom>Height</Typography>
        <Slider
          value={height || 512}
          onChange={handleHeightChange}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          min={256}
          max={1024}
          step={8}
          getAriaValueText={handleValueText}
          color="secondary"
          sx={{
            color: "#EBEBEB",
            htight: 4,
            "& .MuiSlider-thumb": {
                height: 20,
                width: 20,
                backgroundColor: "var(--bg-slider-yellow)",
                border: "2px solid var(--primary)",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&:before": {
                  display: "none",
                },
            },
            "& .MuiSlider-track": {
                border: "none",
                backgroundColor: "var(--bg-slider-yellow)",
              },
            "& .MuiSlider-valueLabel": {
                lineHeight: 1.2,
                fontSize: 12,
                background: "unset",
                padding: 0,
                width: 32,
                height: 32,
                backgroundColor: "var(--bg-slider-yellow)",
                color: "var(--primary)"
              },
          }}
        />{/*
        <Box 
        sx={{
          width: `${60*(width || 512)/500}px`,
          height: `${60*(height || 512)/500}px`,
          backgroundColor: "var(--bg-slider-yellow)",
          mt: 2,
          borderRadius: "4px"
        }}/>*/}
        <div className="d-flex justify-content-between w-100">
          <Typography variant="caption">Min size: 256px</Typography>
          <Typography variant="caption">Max size: 1024px</Typography>
        </div>
      </div>
      <div className="info-size text-center">
        {(width)} x {(height)}
      </div>
      
    </div>
  );
}

export default SizeSelector;
