import React from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import "./Pagination.css";

/**
 *@component is return page items
 * @return  {Jsx}  It returns jsx for users data pages.
 */

export default function PaginationRounded({count, page, onChange}) {
  return (
    <div className="pagination_container">
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        shape="rounded"
        hideNextButton
        hidePrevButton
        renderItem={(item) => <PaginationItem {...item} />}
      />
    </div>
  );
}
