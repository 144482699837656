import { createSlice } from "@reduxjs/toolkit";
import { getDashboardDataByDate } from "../Thunks/Thunks";

const initialState = {
  dashBoardData: [],
  loading: false,
};

const dashboardDataSlice = createSlice({
  name: "Dashboard",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardDataByDate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardDataByDate.fulfilled, (state, action) => {
        state.loading = true;
        state.dashBoardData = action?.payload;
      })
      .addCase(getDashboardDataByDate.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload.message;
      });
  },
});

const { reducer } = dashboardDataSlice;
export default reducer;
