import React from "react";
import "./ImagePreview.scss";
/**
 *@component ImagePreview Component to show preview of picture and also it can be zoomed in and out
 *
 * @param  {String}  mainImageUrl  Category Image
 * @param  {function}  setZoom to set zoom value of image
 * @param  {function}  togglePopup to set zoom value to default
 * @param  {Number}  zoom imaage zoom value
 * @return  {Jsx}  It returns jsx for image preview.
 *
 */

function ImagePreview({ togglePopup, mainImageUrl, setZoom, zoom }) {
  const preventClose = (event) => {
    event.stopPropagation();
  };
  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };
  const handleZoomOut = () => {
    if (zoom > 0.7) {
      setZoom(zoom - 0.1);
    }
  };
  return (
    <div className="popup" onClick={preventClose}>
      <div className="overlay" onClick={togglePopup}></div>
      {/* The overlay div is use for: when you click outside of the popup image the popup will disappear */}
      <div className="popup-button-section">
        <button onClick={handleZoomIn} className="btn btn-zoomUp me-2">
          <i className="ri-zoom-in-line"></i>
        </button>
        <button onClick={handleZoomOut} className="btn btn-zoomDown me-2">
          <i className="ri-zoom-out-line"></i>
        </button>
        <button className="btn close_popUp-btn " onClick={togglePopup}>
          <i className="ri-close-line"></i>
        </button>
      </div>
      <img
        src={mainImageUrl}
        alt="saveImagedImage"
        className="mainImg"
        style={{ transform: `scale(${zoom})`, height: "100%" }}
      />
    </div>
  );
}

export default ImagePreview;
