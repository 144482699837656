import React, { useState } from "react";
import "./TimelineGraph.scss";
import ReactECharts from "echarts-for-react";

/**
 * @component to show the data in form of timeline graph
 * @return  {Jsx}  It returns jsx for render the user data in form of graph.
 * )
 */

const TimelineGraph = ({ graphData, dateData }) => {

  const chartdata = [
    graphData.map((item) => item.Date),
    graphData.map((item) => item.Generated),
    graphData.map((item) => item.Saved),
    graphData.map((item) => item.Searches),
  ];

  const maxValGenSaved = Math.max(...chartdata[1], ...chartdata[2]);
  const intervalGenSaved = parseInt((maxValGenSaved/10).toFixed(0));

  const maxValSearches = Math.max(...chartdata[3]);
  const intervalSearches= parseInt((maxValSearches/10).toFixed(0));

  const symbolSize = 8;
  const option = {
    title: {
      text: "Timeline",
      textStyle: {
        color: "#8A8A8A",
        fontSize: "16px",
        fontFamily: "Montserrat",
      },
    },
    grid: {
      left: "5%",
      right: "5%",
      bottom: "20%",
    },
    color: ["#E4F614", "#FFD1D1", "#C9FFEF"],
    legend: {
      data: ["Generated", "Saved", "Searches"],
      bottom: "0%",
      icon: "rect",
      left: "0%",
    },
    xAxis: [
      {
        type: "category",
        data: chartdata[0],
        axisPointer: {
          type: "shadow",
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "",
        min: 0,
        max: maxValGenSaved,
        interval: intervalGenSaved,
      },
      {
        type: "value",
        name: "",
        min: 0,
        max: maxValSearches,
        interval: intervalSearches,
        axisLabel: {
          show: true,
          formatter: "{value}",
        },
      },
    ],
    series: [
      
      {
        name: "Saved",
        type: "bar",
        stack: "total",
        data: chartdata[2],
      },
      {
        name: "Generated",
        type: "bar",
        stack: "total",
        data: chartdata[1],
        barWidth: "20%",
      },
      {
        name: "Searches",
        type: "line",
        symbolSize: symbolSize,
        yAxisIndex: 1,
        data: chartdata[3],
      },
    ],
  };
  return (
    <div className="timeline-graph-wrapper">
      <ReactECharts option={option} />
    </div>
  );
};

export default TimelineGraph;
