import React from "react";

export const SubAccTable = ({ tableData, handleDeleteItem, roleFilter }) => {
  let tableIndex = 0;
  return (
    <div className="tab-content_wrapper">
      <div className="table-responsive">
        <table className="table ">
          <thead>
            <tr>
              <th scope="col">S.N</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.map((item, index) => {
                if(item.role===roleFilter){
                  tableIndex ++;
                  return (
                    <tr key={index}>
                      <th scope="row">{tableIndex}</th>
                      <td>
                        <div className="date ms-2">{item.name}</div>
                      </td>
                      <td>{item.email}</td>
                      <td>
                        <button
                          className="btn-delete"
                          onClick={() => handleDeleteItem(item.id)}
                        >
                          <i className="ri-delete-bin-6-line"></i>
                        </button>
                      </td>
                    </tr>
                  );
                }
              })
            ) : (
              <tr>
                <td colSpan={5} className="no-data text-center">
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
