import React from "react";
import { images } from "../../constants";
import CircularProgress from '@mui/material/CircularProgress';

const PendingAlertNotification = () => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-between">
      <div className="progress-container">
        <CircularProgress color="primary"/> {/* for some reason this is not centered..... TBD */}
      </div>
      <div className="pending-info">
        <h2>Generating...</h2>
        <span>Please be patient, your request is being processed.</span>
      </div>
    </div>
  );
};

export default PendingAlertNotification;
