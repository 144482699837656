import React, {useEffect, useState} from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { images } from "../../constants";


import "./NewSearch.scss";

/**
 *@component Component to show filter to search categories
 *
 * @return  {Jsx}  It returns jsx for render category search filters.
 *
 */

function SearchFilter({setType, type, keyword, setKeyword}) {
  const handleSelectFilter = (e) => {
    setType(e.target.value)
  }

  const onChangeHandle = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <div className="category_filter_container">
      <div className="content-title">
        <div className="form-control">
          <img src={images.SearchIcon1} />
          <input
            className="ms-1 search-input"
            placeholder="Search"
            name="keyword"
            value={keyword ?? ""}
            onChange={onChangeHandle}
            // onBlur={() => setKeyword(inputValues.keyword)}
          />
        </div>
      </div>
      {/* <div>Choose the best image category to start creating new images</div> */}
      <Select
        sx={{width: '30%'}}
        value={type}
        onChange={(event) => {handleSelectFilter(event)}}
        displayEmpty
        size="small"
        inputProps={{ "aria-label": "Without label" }}
        className="filter_select"
        startAdornment={
          <InputAdornment position="start" style={{ marginRight: "5px" }}>
            Filter:
          </InputAdornment>
        }
      >
        <MenuItem value="All">All</MenuItem>
        <MenuItem value="Private">Private</MenuItem>        
        <MenuItem value="Public">Public</MenuItem>
      </Select>
    </div>
  );
}

export default SearchFilter;
