import { configureStore } from "@reduxjs/toolkit";
import TypeAPromptReducer from "../Slice/TypeAPromptSlice";
import CategorySlice from "../Slice/Category";
import AuthSlice from "../Slice/Auth";
import DashboardReducer from "../Slice/DashboardSlice";
import UseImageSlice from "../Slice/UseImage";
import UpdateCategorySlice from "../Slice/UpdateCategory";
import CategorytoImgSlice from "../Slice/CategorytoImgSlice";
import AccountReducer from "../Slice/AccountSlice";
import SubAccountReducer from "../Slice/SubAccountSlice";
import getAllSavedImagesSlice from "../Slice/GeneratedImagesSlice";
/**
 * This is a redux store where we will connect all the slice with the application.
 * @param options — The store configuration.
 * @returns — A configured Redux store.
 */
export const store = configureStore({
  reducer: {
    dashboardState: DashboardReducer,
    typeAPrompt: TypeAPromptReducer,
    categoryState: CategorySlice,
    auth: AuthSlice,
    useImage: UseImageSlice,
    category: UpdateCategorySlice,
    category2img: CategorytoImgSlice,
    account: AccountReducer,
    subAccount: SubAccountReducer,
    savedImages: getAllSavedImagesSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
