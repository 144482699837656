import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import { Navigate, NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { signIn } from "./../../firebase/Authentication/Authentication";

import { getUserInformation, loginUser } from "../../redux/Thunks/Thunks";
import { resetState } from "../../redux/Slice/Auth";
import "react-toastify/dist/ReactToastify.css";
import InputPassword from "../../components/CommonSection/InputPassword/InputPassword";
import { current } from "@reduxjs/toolkit";



const LogIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const user = query.get('user')
  const { loading, isError, errorMessage } =
    useSelector((state) => state.auth) || {};

  const [inputValues, setInputValues] = useState({email: user});
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const { email, password } = inputValues;
    if (email && password) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputValues]);

  const handleSubmit = async () => {
    const currentUser = await signIn(inputValues?.email, inputValues?.password);
    if(currentUser.accessToken) {
      navigate('/dashboard')
    }
  };

  const inputChangeHandle = (e) => {
    const { name, value } = e.target || {};
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  return (

    <div className="signLog">
      {/* <div className="container"> */}
      <div className="signLog_container">
        <div className="row d-flex justify-content-center">
          <div className=" col-sm-8 col-md-6">
            <div className="header-wrapper d-flex align-items-center justify-content-between">
              <div className="brand d-flex align-items-center ">
                <img src={images.logo} alt="logo" className="brand_logo" />
              </div>
              {/*
              <NavLink to="/signup" className="signLog_link">
                Sign Up
              </NavLink> */}
            </div>

            <div className="signLog_wrapper">
              <div className="content-title">
                <h3>Log In</h3>
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter email address"
                  value={inputValues.email}
                  onChange={inputChangeHandle}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Password</label>
                <InputPassword
                  name="password"
                  placeholder="Enter password"
                  onChange={inputChangeHandle}
                />
              </div>

              <div className="mb-3">
                <button
                  className="btn btn-primary w-100 btn-yellow"
                  type="submit"
                  disabled={isDisabled}
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <CircularProgress
                      style={{
                        width: "25px",
                        color: "white",
                        height: "25px",
                      }}
                    />
                  ) : (
                    "Log In"
                  )}
                </button>
              </div>
{/*
              <div className="content-title">
                <span>
                  Don't have an account yet?
                  <NavLink to="/signup" className="signLog_link ms-2">
                    Sign up Now
                  </NavLink>
                </span>
              </div>
*/}
              <div className="content-title">
                <span>
                  Did you forgot your password?
                  <NavLink to="/password-recovery" className="signLog_link ms-2">
                    Recover Password
                  </NavLink>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6 rightImg">
            <img src={images.LogBg} alt="" className="signLog_image" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LogIn;
