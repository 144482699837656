import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../ApiService/ApiService";
import { DUMMY_IMAGES_DATA } from "../../constants";
import { signIn } from "./../../firebase/Authentication/Authentication";
import { useNavigate } from "react-router";
import { text2img, getUserSavedImages, img2img, getAllCategoriesFunc, createCategoryFunc, text2imgFromCategory, img2imgFromCategory } from "../../firebase/Setup/Setup";
import { getImgUrlAndPath } from "./../../firebase/Storage/Storage";
import { toast } from "react-toastify";


const BASE_URL = "https://us-central1-pixelbomb-98b8d.cloudfunctions.net/expressApi";
/**
 * Thunk for making an api async call.
 * This thunk is a dummy thunk for now.
 * @param typePrefix
 * @param payloadCreator
 * @param options
 * @returns api response data
 */

export const getDashboardDataByDate = createAsyncThunk(
  "/getDashboardDataByDate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/api/dashboard/get-dashboard-data`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const text2Img = createAsyncThunk(
  "/text2img",
  async (data, { rejectWithValue }) => {
    try {
      const response = await text2img({
        prompt: data.prompt,
        width: data.width,
        height: data.height,
        samples: data.samples,      
        negative_prompt: data.negative_prompt,
        postscripts: data.postscripts,        
        presetObject: data.presetObject
      })
      if(response?.data?.status === 400)
        toast.warning('No credits left. Please upgrade your plan.', {
          position: "top-center",
        });
      return response?.data?.data;
    } catch (err) {
      if(err?.response?.data?.error?.code === 'unauthenticated' || err?.response?.data?.message === 'Login to Access This Resourse') {
        localStorage.removeItem('pixel_bomb_token')
      }
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const img2Img = createAsyncThunk(
  "/img2img",
  async (data, { rejectWithValue }) => {
    try {
      const response = await img2img(data)
      return response?.data?.data;
      // const response = await apiService().post(`${BASE_URL}/img2img`, data);
      // return {...response?.data?.data, inputData: data} ?? {};
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

/**
 * Thunk for making an api async call for all categories.
 * @param data
 * @returns api response data
 */

export const getAllCategories = createAsyncThunk(
  "Categories/getAllCategories",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllCategoriesFunc(data);
      return response?.data ?? {};
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);


export const registerUser = createAsyncThunk(
  "/register",
  async (data, { rejectWithValue }) => {
    try {
      await apiService().post(`${BASE_URL}/register`, data);
      const navigate = useNavigate();
      const currentUser = await signIn(data.email, data.password);
      if(currentUser.accessToken) {
        navigate('/user')
      }
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.response?.data?.message || err?.message,
        status: err?.response?.data?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const loginUser = createAsyncThunk(
  "/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/login`, data);
      return response?.data  
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.response?.data?.message || err?.message,
        status: err?.response?.data?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const createCategory = createAsyncThunk(
  "Categories/createCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createCategoryFunc(data);
      if(response?.data?.status === 400)
        toast.warning('No credits left. Please upgrade your plan.', {
          position: "top-center",
        });
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.response?.data?.message || err?.message,
        status: err?.response?.data?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const editCategory = createAsyncThunk(
  "/editCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/editCategory`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.response?.data?.message || err?.message,
        status: err?.response?.data?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const text2imgFromCat = createAsyncThunk(
  "text2imgFromCat/text2imgFromCat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await text2imgFromCategory(data);
      if(response?.data?.status === 400)
        toast.warning('No credits left. Please upgrade your plan.', {
          position: "top-center",
        });
      return response?.data?.data;
      } catch (err) {

      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);
export const img2imgFromCat = createAsyncThunk(
  "img2imgFromCat/img2imgFromCat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await img2imgFromCategory(data);
      if(response?.data?.status === 400)
        toast.warning('No credits left. Please upgrade your plan.', {
          position: "top-center",
        });
      return response?.data?.data;
      } catch (err) {

      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const getUserInformation = createAsyncThunk(
  "Account/getUserInformation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/api/users/getUserInformation`, data);
      return response?.data?.info;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const editName = createAsyncThunk(
  "Account/editName",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/editName`, data);
      if (response.data.success)
        return {name: data.newName};
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const editEmailAddress = createAsyncThunk(
  "Account/editEmailAddress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/editEmailAddress`, data);
      if(response.data.success)
        return {email: data.email}
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const changePassword = createAsyncThunk(
  "Account/changePassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/changePassword`, data);
      return response.data.success
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const getSubAccounts = createAsyncThunk(
  "/getSubAccounts",
  async ({ rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/getSubAccounts`);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

export const createSubAccount = createAsyncThunk(
  "/createSubAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiService().post(`${BASE_URL}/createSubAccount`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);

const getAllSavedDummyImages = (data) => {
  const { keyword, limit, page } = data;
  const pageFilterdData = DUMMY_IMAGES_DATA.slice(
    (page - 1) * limit,
    page * limit
  );

  let updateDummyData = [];
  if (keyword) {
    updateDummyData = pageFilterdData.filter((item) =>
      item.title.toLowerCase().includes(keyword.toLowerCase())
    );
  } else {
    updateDummyData = pageFilterdData;
  }
  return updateDummyData;
};

export const getAllSavedImagesThunk = createAsyncThunk(
  "getAllSavedImagesSlice/getAllSavedImages",
  async (data, { rejectWithValue }) => {
    try {
      //api code will uncomment after api is working
      const response = await getUserSavedImages({});
      let resArr = [];
      if (response.data.saveImages) {
        const promises = response.data.saveImages.map(async (item) => {
          return await getImgUrlAndPath([item.path])
        })
        await Promise.all(promises).then((results) => {
          results.forEach((result, index) => {
            let newDate = response.data.saveImages[index]?.date ? (`${new Date(response.data.saveImages[index].date._seconds * 1000).getFullYear()}-${new Date(response.data.saveImages[index].date._seconds * 1000).getMonth()+1}-${new Date(response.data.saveImages[index].date._seconds * 1000).getDate()}`) : ''
            resArr.push({linkData: result[0], date: newDate})
          });
        })
        return {saveImages: resArr} ?? {};
      }
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
        status: err?.status || 500,
        code: err?.code,
      });
    }
  }
);
