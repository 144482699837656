import React from "react";
import GenerateImages from "../GenerateImages/GenerateImages";
import { images } from "../../constants";

const PreviousSearch = ({
  showResult,
  tableData,
  handleResult,
  setResultData,
  generatedImage,
  setGeneratedImage,
  icon,
}) => {
  return (
    <div className="tab-content_wrapper">
      {!showResult && (
        <div className="table-responsive">
          <table className="table ">
            <thead>
              <tr>
                <th scope="col" style={{minWidth: '190px'}}>Created</th>
                <th scope="col">Prompt</th>
                <th scope="col" style={{minWidth: '140px'}}></th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.sort((a, b) => b?.updatedAt?.seconds - a?.updatedAt?.seconds).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="table_createDate d-flex align-items-center">
                          <img src={icon} alt="" />
                          <div className="date ms-2">{item?.updatedAt ? `${new Date(item?.updatedAt.seconds*1000).getFullYear()}-${new Date(item?.updatedAt.seconds*1000).getMonth()+1}-${new Date(item?.updatedAt.seconds*1000).getDate()} ${new Date(item?.updatedAt.seconds*1000).getHours()}:-${new Date(item?.updatedAt.seconds*1000).getMinutes()}:${new Date(item?.updatedAt.seconds*1000).getSeconds()}` : ''}</div>
                        </div>
                      </td>
                      <td>
                        <p>{item.prompt}</p>
                      </td>
                      <td>
                        <button
                          className="btn-viewResult"
                          onClick={() => {
                            setResultData(item)
                            setGeneratedImage(item.images)
                            handleResult(true);
                          }}
                        >
                          View result
                          <img src={images.RightVectorIcon} alt="" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className="no-data text-center">
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PreviousSearch;
