import React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

/**
 * Page to preview new searches images, pending images and previous images
 * @param  {Number} max max value of range slider
 * @component
 * @Slider show ranges between min and max
 * @return  {Jsx}  It returns jsx to render range slider.
 * )
 */

const PrettoSlider = styled(Slider)({
  color: "#EBEBEB",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "var(--bg-slider-yellow)",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "var(--bg-slider-yellow)",
    border: "2px solid var(--primary)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    backgroundColor: "var(--bg-slider-yellow)",
    color: "var(--primary)"
  },
});

export default function CustomizedSlider({
  max,
  name,
  value,
  handlePromptData,
  labelSwitch,
  min = 1,
}) {
  return (
    <Box sx={{ width: "85%", marginTop: "5px" }}>
      <PrettoSlider
        valueLabelDisplay={labelSwitch}
        aria-label="pretto slider"
        defaultValue={512}
        min={1}
        max={max}
        name={name}
        value={value ? value : 512}
        onChange={handlePromptData}
      />
    </Box>
  );
}
