import { createSlice } from "@reduxjs/toolkit";
import { createSubAccount, getSubAccounts } from "../Thunks/Thunks";

const initialState = {
  subAccountData: [],
  success: "",
  loading: false,
};

const subAccountSlice = createSlice({
  name: "Sub Account",
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getSubAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubAccounts.fulfilled, (state, action) => {
        state.loading = true;
        state.subAccountData = action?.payload;
      })
      .addCase(getSubAccounts.rejected, (state, action) => {
        state.loading = true;
      })

      .addCase(createSubAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSubAccount.fulfilled, (state, action) => {
        state.loading = true;
        state.success = action?.payload;
      })
      .addCase(createSubAccount.rejected, (state, action) => {
        state.loading = true;
      });
  },
});

const { reducer } = subAccountSlice;
export default reducer;
