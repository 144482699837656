import React, { useState } from "react";

import "./InputPassword.scss";
const InputPassword = (props) => {
  //toggle passwor Icon
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordIcon = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className="passwordToggle">
        <input
          type={showPassword ? "text" : "password"}
          name={props.name}
          className="form-control "
          placeholder={props.placeholder}
          onChange={props.onChange}
        />
        <i
          className={` ${showPassword ? "ri-eye-line" : "ri-eye-close-line"}`}
          onClick={togglePasswordIcon}
        ></i>
      </div>
    </>
  );
};

export default InputPassword;
