import React, { useState, useEffect } from "react";
import { Carousel, Form } from "react-bootstrap";
import { CgClose } from "react-icons/cg";
import BaseModal from "../BaseModal/BaseModal";
import "./CategoryViewModal.scss";
import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";
import { images } from "../../constants";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { GenerateAlertNotification } from "../GenerateAlertNotification/GenerateAlertNotification.js";
/**
 * Component to open the modal-box(pop-up) in the category page
 *
 * @CategoryViewModal
 * @example
 * @param   {<any>} openModal <Opens the Modal>
 * @param   {<any>} setOpenModal <Sets the state to open the modal>
 * @param   {<any>} cagetoryData <The category data of images>
 * @param   {<any>} index <the index count of the current image>
 * )
 */

const CategoryViewModal = ({
  openModal,
  setOpenModal,
  cagetoryData,
  index,
  setIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(index || 0);
  // const [value, setValue] = useState(0)

  /**
   * Used to handle navigation of images
   */
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setCurrentIndex(selectedIndex);
  };

  const { data, name, description } = cagetoryData;

  const [loadingAlert, setLoadingAlert] = useState(false);
  const [imageGeneratedAlert, setImageGeneratedAlert] = useState(false);

  const generateHandler = (e) => {
    setLoadingAlert(!loadingAlert);
    e.preventDefault();
  };

  useEffect(() => {
    setCurrentIndex(index || 0);
  }, [index]);

  /**
   * Creates Material Ui custom Slider
   */
  const CustomSlider = styled(Slider)({
    color: "#ed5683",
    "& .MuiSlider-valueLabel": {
      fontSize: 12,
      fontWeight: "normal",
      top: -6,
      backgroundColor: "unset",
      color: "#ed5683",
      "&:before": {
        display: "none",
      },
    },
  });
  return (
    <>
      <BaseModal openModal={openModal} setOpenModal={setOpenModal}>
        <div className="modalContainer">
          <div className="container p-0">
            <div className="row g-0">
              <div className=" col-12 col-lg-7 col-xl-7 ">
                <div className="leftSide">
                  <Carousel
                    interval={null}
                    activeIndex={index}
                    onSelect={handleSelect}
                  >
                    {data.map((item, index) => {
                      return (
                        <Carousel.Item key={index}>
                          <div className="carouselPagination">
                            {index + 1}/12
                          </div>

                          <img
                            className="d-block modalImg"
                            src={item.image}
                            alt={`Slide-${index + 1}`}
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
              <div className=" col-12 col-lg-5 col-xl-5 ">
                <div className="rightSide ">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="content-title align-self-end m-0 mt-3">
                      <h3> {name}</h3>
                      <span>{description}</span>
                    </div>
                    <CgClose
                      className="close-modal"
                      size={25}
                      onClick={() => setOpenModal(false)}
                    />
                  </div>
                  <div className="mt-3">
                    <div className="form-title">
                      <h4>{data[currentIndex].title || ""}</h4>
                    </div>
                    <p className="sub-title">
                      {data[currentIndex].description || ""}
                    </p>
                  </div>
                  <Form>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        name=""
                        rows="3"
                        placeholder="Eg. A girl with long blonde hair and big eyes in the grass at sunset"
                      ></textarea>
                    </div>
                    <div className="mt-3">
                      <div className="form-title">
                        <h4>Size of Image</h4>
                      </div>
                      <p className="sub-title">
                        Select the format this image will be created in
                      </p>
                      <div className="form-group">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected value="square">
                            Square
                          </option>
                          <option value="landscape">Landscape</option>
                          <option value="portrait">Portrait</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="form-title">
                        <h4>Quantity</h4>
                      </div>
                      <p className="sub-title">
                        The Number of Images to generate
                      </p>
                      <div className="form-group">
                        <div className="field d-flex align-items-center justify-content-center ">
                          <span className="value left">0</span>
                          <CustomSlider
                            className="mt-4 mb-3 range"
                            valueLabelDisplay="on"
                            min={0}
                            max={4}
                            step={1}
                            aria-label="Quantity"
                            defaultValue={3}
                            color="primary"
                          />
                          <span className="value right ">10</span>
                        </div>
                      </div>
                    </div>
                    <div className=" mt-4 d-flex flex-row justify-content-end flex-wrap">
                      <button
                        className="btn btn-goback mb-2"
                        onClick={() => setOpenModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary ms-2 mb-2"
                        onClick={generateHandler}
                      >
                        Generate
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseModal>
      <GenerateAlertNotification
        imageGeneratedAlert={imageGeneratedAlert}
        setImageGeneratedAlert={setImageGeneratedAlert}
        loadingAlert={loadingAlert}
        setLoadingAlert={setLoadingAlert}
      />
    </>
  );
};

export default CategoryViewModal;
