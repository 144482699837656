import React, { useRef } from "react";

import Picture from "../../../../assets/Icons/Picture.svg";
import Close from "../../../../assets/Icons/Close.svg";
import data from "../../../../TestData/Categories";
import { toast } from "react-toastify";

import "./ImageUpload.scss";

/**
 *@component Component to upload images and also to display list of images
 *
 * @return  {Jsx}  It returns jsx for list of images.
 */

function ImageUpload({ setSelectedFile, selectedFile }) {
  const inputFile = useRef(null);

  const onCloseHandle = (selectedIndex) => {
    const updateItems = selectedFile.filter(
      (item, index) => !(selectedIndex === index)
    );
    setSelectedFile(updateItems);
  };

  const onClickHandler = () => {
    inputFile.current.click();
  };

  return (
    <div className="image_upload_container">
      <div className="title">Training Images</div>
      <div className="description">Upload maximum 20 - 512x512 images to train your model.</div>
      <div className="description">You can use this free tool - <a href="https://www.birme.net/" target="_blank">https://www.birme.net/</a> to resize all your images into 512 x 512 size at the same time automatically.</div>
      <div className="description">If you're going to upload images of humans, then we highly recommend you upload 16 closeup images of the person's face and 4 full body images of the person (20 images in total) for best results. If you're going to upload images of other objects, uploading 10 high quality images is fine.</div>
      <input
        type="file"
        multiple
        id="imageUploader"
        onChange={(e) => {
          const files = Array.from(e.target.files);
          if(files.length>20 || selectedFile.length+files.length>20){
            toast.error("Please reduce the number of images to max. 20.", {
              position: "top-center",
            });
          }else{
          files.forEach(file=>{

            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
              const { width, height } = img;
              const isWidthValid = width == 512;
              const isHeightValid = height == 512;
              if (!isWidthValid || !isHeightValid) {
                toast.error("The following image is not in the desired format: "+file.name+". Please use 512x512 images only. You can use brime.net to create them.", {
                  position: "top-center",
                });
              }else{
                let reader = new FileReader();

                reader.onload = function (e) {
                  let pngBase64Prefix = 'data:image/png;base64,';
                  let imageData = reader.result;
                  let fileName = file.name
                  if(imageData.includes(';base64,')) {
                    imageData = pngBase64Prefix + imageData.split(';base64,')[1];
                    fileName = fileName.split('.')[0] + '.png';
                  }
                  setSelectedFile((current) => [...current, {name: fileName, data: imageData}]);
                };
                reader.readAsDataURL(file);
              }
            }

            
          })
        }
        }}
        ref={inputFile}
        style={{ display: "none" }}
        accept=".jpg,.jpeg,.png"
      />
      <div onClick={onClickHandler} className="upload_btn">
        <img src={Picture} alt="" />
        <span className="text">Upload</span>
      </div>

      {selectedFile?.length > 0 && (
        <div className="no_of_images">Number of images: {selectedFile.length}</div>
      )}
      {/* Uploaded images */}
      <div className="uploaded_images">
        {selectedFile.map((item, index) => {
          return (
            <div className="img" key={index}>
              <div
                style={{
                  width: "112px",
                  height: "100px",
                  backgroundSize: "cover",
                  backgroundImage: `url(${item.data})`,
                  border: "1px solid #ED5683",
                  filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1))",
                  borderRadius: "4px",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              ></div>
              <div
                className="close_img_container"
                onClick={() => onCloseHandle(index)}
              >
                <img src={Close} alt="" className="close_img" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ImageUpload;
