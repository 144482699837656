import React from "react";
import "./ConversionGraph.scss";
import ReactECharts from "echarts-for-react";

/**
 * @component to show the data in form of conversion graph
 * @return  {Jsx}  It returns jsx for render the user data in form of graph.
 * )
 */

const ConversionGraph = ({ graphData }) => {

  const generatedTotal = graphData.reduce((acc, curr) => acc + curr.Generated, 0);
  const savedTotal = graphData.reduce((acc, curr) => acc + curr.Saved, 0);
  const percent = (savedTotal*100/generatedTotal || 0).toFixed(0)

  const option = {
    title: {
      text: "Conversion",
      textStyle: {
        color: "#110041",
        fontSize: "16px",
        fontFamily: "Montserrat",
      },
    },
    color: ["#C9FFEF", "#FFD1D1"],
    tooltip: {
      trigger: "item",
    },
    legend: {
      bottom: "2%",
      left: "center",
      itemStyle: {
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: savedTotal,
            name: "Saved",
            itemStyle: {
              normal: {
                borderWidth: 1.5,
                borderColor: "#110041",
              },
            },
          },
          {
            value: generatedTotal,
            name: "Generated",
            itemStyle: {
              normal: {
                borderWidth: 1.5,
                borderColor: "#110041",
              },
            },
          },
          
        ],
      },
    ],
    graphic: {
      elements: [
        {
          type: "text",
          left: "center",
          top: "middle",
          z: 999,
          style: {
            text: `${percent}%`,
            textAlign: "center",
            fontSize: 20,
            fontWeight: 600,
            fill: "#5A56CA",
          },
        },
      ],
    },
  };
  return (
    <div className="conversion-graph-wrapper">
      <ReactECharts option={option} />
    </div>
  );
};

export default ConversionGraph;
