import { createSlice } from "@reduxjs/toolkit";
import { getAllCategories } from "../Thunks/Thunks";

/**
 * @property {string} loading - api call status called in thubk
 * @property {array} data     - loading data in the data variable return from thunk
 */
const initialState = {
  loading: false,
  data: [],
};

/**
 * @property {string} name  - Name of the Slice
 * @property {object} initialState  - loading the slice with the its format
 *
 */
export const categoriesSlice = createSlice({
  name: "Categories",
  initialState,
  /**
   * extrareducer is used when we are passing any thunk in dispatch action
   */
  extraReducers: {
    [getAllCategories.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [getAllCategories.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false,
      success: true,
    }),
    [getAllCategories.rejected]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload
    }),
  },
});

const { reducer } = categoriesSlice;

export default reducer;
