import React, { useState, useEffect,useContext } from "react";
import Sidebar from "../Sidebar/SideBar";
import Footer from "../Footer/Footer";
import TopNav from "../TopNav/TopNav";
import { images } from "../../constants";
import { useNavigate } from "react-router";
import "./Layout.scss";
import { Outlet } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { AuthContext } from "../../firebase/Authentication/context";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: images.HomeIcon,
    opacity: 1
  },
  {
    path: "/category",
    name: "Category",
    icon: images.CategoryIcon,
  },

  {
    path: "/type-prompt",
    name: "Type a prompt ",
    icon: images.AddText,
  },
  // {
  //   path: "/use-images",
  //   name: "Use Images",
  //   icon: images.PhotoIcon,
  // },
  {
    path: "/saved-images",
    name: "Saved Images",
    icon: images.Album,
  },
];

const Layout = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  //active link items
  const [activeIndex, setActiveIndex] = useState(
    localStorage.getItem("activeTab") || routes[0]
  );
  useEffect(() => {
    //set active
    localStorage.setItem("activeIndex", activeIndex);
  }, [activeIndex]);
  


  return (
    <>
      <div className="layout d-flex">
        <Sidebar
          routes={routes}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
        <div className="main__layout">
          <TopNav activeIndex={activeIndex} />
          <div className="main__layout-wrapper">
            <div className="content">
              <div className="container-fluid">
                {/* routing outlets */}
                <Outlet />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
