import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";

import { registerUser } from "../../redux/Thunks/Thunks";
import { resetState } from "../../redux/Slice/Auth";
import "react-toastify/dist/ReactToastify.css";
import InputPassword from "../../components/CommonSection/InputPassword/InputPassword";
import { images } from "../../constants";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, token, isError, errorMessage } =
    useSelector((state) => state.auth) || {};

  const [inputValues, setInputValues] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage, {
        position: "top-center",
      });
      dispatch(resetState());
    }
    if (token) {
      navigate("/dashboard");
      dispatch(resetState());
    }
  }, [token, isError]);

  useEffect(() => {
    const { name, email, password, repeatPassword, coupon } = inputValues;
    if (name && email && password === repeatPassword && coupon) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputValues]);

  const inputChangeHandle = (e) => {
    const { name, value } = e.target || {};
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    dispatch(
      registerUser({
        name: inputValues?.name,
        email: inputValues?.email,
        password: inputValues?.password,
        coupon_code: inputValues?.coupon,
        plan: inputValues?.plan || "basic",
      })
    );
  };

  return (
    <div className="signLog">
      {/* <div className="container"> */}
      <div className="signLog_container">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-8 col-md-6">
            <div className="header-wrapper d-flex align-items-center justify-content-between">
              <div className="brand d-flex align-items-center ">
                <img src={images.logo} alt="logo" className="brand_logo" />
                <h5 className="brand_name ms-3 mb-0">PixelBoom</h5>
              </div>
              <NavLink to="/login" className="signLog_link">
                Log In
              </NavLink>
            </div>

            <div className="signLog_wrapper">
              <div className="content-title">
                <h3>Create your account</h3>
                <span>
                  Already have an account?
                  <NavLink to="/login" className="signLog_link ms-2">
                    Log In
                  </NavLink>
                </span>
              </div>
              <div>
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter Name"
                    onChange={inputChangeHandle}
                  ></input>
                </div>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter email address"
                    onChange={inputChangeHandle}
                  ></input>
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <InputPassword
                    name="password"
                    placeholder="Enter password"
                    onChange={inputChangeHandle}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Re-enter Password</label>
                  <InputPassword
                    name="repeatPassword"
                    placeholder="Retype Password"
                    onChange={inputChangeHandle}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Coupon code (optional)</label>
                  <input
                    type="text"
                    name="coupon"
                    className="form-control"
                    placeholder="Enter coupon code"
                    onChange={inputChangeHandle}
                  />
                </div>
                <div className="mb-3">
                  <button
                    className="btn btn-primary w-100 btn-yellow"
                    type="submit"
                    name="submit"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{
                          width: "25px",
                          color: "white",
                          height: "25px",
                        }}
                      />
                    ) : (
                      "Create account"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 rightImg">
            <img src={images.LogBg} alt="" className="signLog_image" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
