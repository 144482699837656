import { images } from "../constants"


export default [
    {
      image: images.Sample,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.Nature,
      title: "AbstractHome",
      isEdit: true,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      isEdit: true,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      isEdit: true,
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    },
    {
      image: images.categoryCardImage,
      title: "Abstract",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
    }
  ]
