import { createSlice } from "@reduxjs/toolkit";
import { img2imgFromCat,text2imgFromCat } from "../Thunks/Thunks";

const initialState = {
  aiDataCat2Img: {},
  loading: false,
};

const category2imgSlice = createSlice({
  name: "Type a Prompt",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(img2imgFromCat.pending, (state) => {
        state.loading = true;
      })
      .addCase(img2imgFromCat.fulfilled, (state, action) => {
        state.loading = false;
        state.aiDataCat2Img = action?.payload;
      })
      .addCase(img2imgFromCat.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload.message;
      })
      .addCase(text2imgFromCat.pending, (state) => {
        state.loading = true;
      })
      .addCase(text2imgFromCat.fulfilled, (state, action) => {
        state.loading = false;
        state.aiDataCat2Img = action?.payload;
      })
      .addCase(text2imgFromCat.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload.message;
      });;
  },
});


const { reducer } = category2imgSlice;
export default reducer;
