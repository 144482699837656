/**
 * @component    Component to edit name of user profile
 */
import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ConfirmModal.scss";
// import { toast } from "react-toastify";

const ConfirmModal = ({
  show,
  setShow,
  handleFinish, 
  presetsList
}) => {
  const handleClose = () => {
    setShow(false);
  };

  
  return (
    <>
      <Modal className="preset-confirm-modal" show={show} onHide={handleClose} centered>
        <div className="row d-flex justify-content-center mod">
          <div className="col-md-12 modal_wrapper">
            <Modal.Header style={{ border: "none" }} closeButton>
              <Modal.Title>
                <div className="content-title">
                  <h6>Confirm the selected presets</h6>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="edit-popup-body">
              <h4>Selected Presets:</h4>
              {presetsList.map((selectedPreset)=>{
                  return <h6>{selectedPreset.presetName}</h6>
              })}
            </Modal.Body>
            <Modal.Footer
              style={{
                border: "none",
                marginBottom: "15px",
                paddingTop: "0px",
              }}
            >
              <button className=" btn btn-goback" onClick={handleClose}>
                No
              </button>

              <button className="btn btn-primary btn-yellow" onClick={handleFinish}>
                Yes
              </button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
