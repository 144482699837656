import { createSlice } from "@reduxjs/toolkit";
import { registerUser, loginUser } from "../Thunks/Thunks";

/**
 * @property {string} loading - api call status called in thubk
 * @property {array} data     - loading data in the data variable return from thunk
 */
const initialState = {
  loading: false,
  data: [],
  success: ""
};

/**
 * @property {string} name  - Name of the Slice
 * @property {object} initialState  - loading the slice with the its format
 *
 */
export const registerUserSlice = createSlice({
  name: "registerUser",
  initialState,
  reducers: {
    resetState: (state) => ({
      ...state,
      loading: false,
      success: false,
      isError: false
    }),
  },
  /**
   * extrareducer is used when we are passing any thunk in dispatch action
   */
  extraReducers: {
    [registerUser.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [registerUser.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false,
      success: true,
      isError: false
    }),
    [registerUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
      success: false,
      isError: true
    }),

    [loginUser.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [loginUser.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false,
      success: true,
      isError: false
    }),
    [loginUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
      success: false,
      isError: true
    }),
  },
});

const { reducer, actions } = registerUserSlice;
export const { resetState } = actions;
export default reducer;
