import { createSlice } from "@reduxjs/toolkit";
import { text2Img } from "../Thunks/Thunks";

const initialState = {
  aiDataText2Img: {},
  loading: false,
};

const typeAPromptSlice = createSlice({
  name: "Type a Prompt",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(text2Img.pending, (state) => {
        state.loading = true;
      })
      .addCase(text2Img.fulfilled, (state, action) => {
        state.loading = false;
        state.aiDataText2Img = action?.payload;
      })
      .addCase(text2Img.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload.message;
      });
  },
});

const { reducer } = typeAPromptSlice;
export default reducer;
