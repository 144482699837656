
import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db, firebaseAuth } from "../Setup/Setup";
import { doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { getIp	 } from "../Setup/Setup";

import { updateDoc, getDoc } from "firebase/firestore";

export const AuthContext = React.createContext();



export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userDoc, setUserDoc] = useState({});
  const [session, setSession] = useState(null);
  const [isTM, setIsTM] =useState(false);
  const [parentAccount, setParentAccount] =useState("");
  const [ip, setUserIp] = useState("");

  const setUserData = (data) => {
    if (data) {
      setUser(data);
    } else {
      setUser(null);
    }
  };
  
  useEffect(() => {
    let unsubscribe;
    if (user) {
      unsubscribe = onSnapshot(doc(db, "Users", user.uid), (snapshot) => {

          // console.log("last:",snapshot.data().lastSession);
          // console.log("curr:",localStorage.getItem("session"));
          setUserDoc(snapshot.data());
          if(snapshot.data().lastSession !== localStorage.getItem('session') && snapshot.data().ip !== localStorage.getItem('ip')) {
            
            localStorage.setItem('session', snapshot.data().lastSession)
            toast.warning("A new login is detected", {
              position: "top-center",
            })
            //alert("The user started a session in another browser. You've been logged out.")
            //window.location.href="/"
            //firebaseAuth.signOut();
          }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);


  useEffect(() => {
    
    firebaseAuth.onAuthStateChanged((user) =>{
        setUserData(user)
        if(user){
          const uuid = crypto.randomUUID();
          const userRef = doc(db, "Users", user.uid);
          let userIp = "";
          localStorage.setItem("pixel_bomb_token", user.accessToken);
          localStorage.setItem("session", uuid);
          getIp().then((ret)=>{
            userIp=ret.data.ip;
            localStorage.setItem("ip", userIp);

            getDoc(userRef).then((doc)=>{
              if(doc)
              {
                const userData= doc.data();
                setUserDoc(userData)
                if(userData.parentAccountId)
                {
                  // console.log("TeamMember session");
                  setIsTM(true);
                  setParentAccount(userData.parentAccountId);
                }
              }
            })
            updateDoc(userRef,{lastSession:uuid, ip: userIp}).then(()=>{
              setSession(uuid);
              setUserIp(userIp);

            });
          })
        }else localStorage.removeItem("pixel_bomb_token")
        });
  }, []);

  return (
    <AuthContext.Provider value={{user,session,isTM,parentAccount, userDoc}}>{children}</AuthContext.Provider>
  );
};
