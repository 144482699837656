import React, { useState, useEffect, useContext } from "react";
import { Navigate, Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "../pages/Landing/Landing";
import Category from "../pages/Category/Category";
import Layout from "../components/Layout/Layout";
import SavedImages from "../pages/SavedImages/SavedImages";
import UseImage from "../pages/UseImage/UseImage";
import UserProfile from "../pages/UserProfile/UserProfile";
import TypePrompt from "../pages/TypePrompt/TypePrompt";
import CategoryResults from "../pages/CategoryResults/CategoryResults";
import LogIn from "../pages/LogIn/LogIn";
import SignUp from "../pages/SignUp/SignUp";
import { db } from "./../firebase/Setup/Setup";
import { toast } from "react-toastify";
import GeneratedAlertNotification from "./../components/AlertNotification/GeneratedAlertNotification";
import { AuthContext } from "../firebase/Authentication/context";
import PasswordRecovery from "../pages/PasswordRecovery/PasswordRecovery";
import PasswordReset from "../pages/PasswordRecovery/PasswordReset";

const { collection, onSnapshot, doc } = require("firebase/firestore");

const Routers = () => {
  const { user, isTM, parentAccount } = useContext(AuthContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tabItem, setTabItem] = useState("")
  const [generatedImage, setGeneratedImage] = useState("")
  const [showResult, setShowResult] = useState("")
  
  const [showResultTypeAPrompt, setShowResultTypeAPrompt] = useState(false);
  const [generatedImageTypeAPrompt, setGeneratedImageTypeAPrompt] = useState([]);
  const [activeTabTypeAPrompt, setActiveTabTypeAPrompt] = useState("newSearches");
  
  const [showResultI2I, setShowResultI2I] = useState(false);
  const [generatedImageI2I, setGeneratedImageI2I] = useState([]);
  const [activeTabI2I, setActiveTabI2I] = useState("newSearches");

  const [showResultCategory, setShowResultCategory] = useState(false);
  const [generatedImageCategory, setGeneratedImageCategory] = useState([]);
  const [activeTabCategory, setActiveTabCategory] = useState("newSearches");



  //view result of alert notification
  const handleView = (item, activedTab) => {
    setTabItem("previousSearches");
    if (activedTab === '/type-prompt') {
      setActiveTabTypeAPrompt("previousSearches")
      setGeneratedImageTypeAPrompt(item);
      setShowResultTypeAPrompt(true);
    }
    if (activedTab === '/use-images') {
      setActiveTabI2I("previousSearches")
      setGeneratedImageI2I(item);
      setShowResultI2I(true);
    }
    if (activedTab === '/category') {
      setActiveTabCategory("previousSearches")
      setGeneratedImageCategory(item);
      setShowResultCategory(true);
    }
    navigate(activedTab)
  };

  /*useEffect(() => {
    getAuth().onAuthStateChanged(function(user) {
      setCurrentUser(user)
      if(user){
        localStorage.setItem("pixel_bomb_token", user.accessToken);}
        else localStorage.removeItem("pixel_bomb_token")
      });
  }, [])*/

  useEffect(() => {
    let unsubscribeGenerationsCategory;
    let unsubscribeGenerations;
    let unsubscribeGenerationsI2I;
    let unsubscribeNewLogin;

    if (user?.uid) {
      const uid = isTM?parentAccount:user.uid;

      unsubscribeGenerationsCategory =onSnapshot(
        collection(db, `Users/${uid}/generationsCategory`), 
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if(change.type === 'modified'){
              let updatedGeneration = change.doc.data();
              updatedGeneration.isDone && toast(
                <GeneratedAlertNotification
                  handleView={() => {handleView(updatedGeneration.images, "/category")}}
                  imgData={updatedGeneration.images}
                />,
                {
                  autoClose: false,
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "generated-alert",
                }
              );
             }   
          })
        },
        (error) => {
          toast.error('Please refresh the page and try again!', {
            position: "top-center",
          });
        }
      );
      unsubscribeGenerations=onSnapshot(
        collection(db, `Users/${uid}/generations`), 
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if(change.type === 'modified'){
              let updatedGeneration = change.doc.data();
              updatedGeneration.isDone && toast(
                <GeneratedAlertNotification
                  handleView={() => {handleView(updatedGeneration.images, "/type-prompt")}}
                  imgData={updatedGeneration.images}
                />,
                {
                  autoClose: false,
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "generated-alert",
                }
              );
             }   
          })
        },
        (error) => {
          toast.error('Please refresh the page and try again!', {
            position: "top-center",
          });
        }
      );
      unsubscribeGenerationsI2I=onSnapshot(
        collection(db, `Users/${uid}/generationsI2I`), 
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if(change.type === 'modified'){
              let updatedGeneration = change.doc.data();
              updatedGeneration.isDone && toast(
                <GeneratedAlertNotification
                  handleView={() => {handleView(updatedGeneration.images, '/use-images')}}
                  imgData={updatedGeneration.images}
                />,
                {
                  autoClose: false,
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "generated-alert",
                }
              );
             }   
          })
        },
        (error) => {
          toast.error('Please refresh the page and try again!', {
            position: "top-center",
          });
        }
      );
      /*const userRef = doc(db, "Users", uid);
      unsubscribeNewLogin = onSnapshot(
        userRef, 
        (doc) => {
          if(doc.data().lastSession !== localStorage.getItem('session')) {
            localStorage.setItem('session', doc.data().lastSession)
            toast.warning("A new login is detected", {
              position: "top-center",
            })
          }
        },
        (error) => {
          toast.error('Please refresh the page and try again!', {
            position: "top-center",
          });
        }
      );*/
    }
    return () => {
      unsubscribeGenerationsCategory && unsubscribeGenerationsCategory();
      unsubscribeGenerations && unsubscribeGenerations();
      unsubscribeGenerationsI2I && unsubscribeGenerationsI2I();
      unsubscribeNewLogin && unsubscribeNewLogin();
    };

  }, [user])

  return (<>
    <Routes>
      <Route path="/login" element={loading && <LogIn />} />
      <Route path="/" element={loading && <LogIn />} />
      <Route path="/signup" element={<SignUp />} />      
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/password-reset" element={<PasswordReset />} />

      {/* Dashboard content route */}
      <Route path="/" element={<Layout />}>
        <Route path="/dashboard" index element={<Dashboard />} />
        <Route Route path = "category"
        element = {
            <Category activeTab = {
              activeTabCategory
            }
            setActiveTab = {
              setActiveTabCategory
            }
            generatedImage = {
              generatedImageCategory
            }
            setGeneratedImage = {
              setGeneratedImageCategory
            }
            showResult = {
              showResultCategory
            }
            setShowResult = {
              setShowResultCategory
            }
            />} />
        <Route path="category/Results" element={<CategoryResults />} />
        <Route Route path = "use-images"
        element = {
            <UseImage activeTab = {
              activeTabI2I
            }
            setActiveTab = {
              setActiveTabI2I
            }
            generatedImage = {
              generatedImageI2I
            }
            setGeneratedImage = {
              setGeneratedImageI2I
            }
            showResult = {
              showResultI2I
            }
            setShowResult = {
              setShowResultI2I
            }
            />} />
        <Route path="saved-images" element={<SavedImages />} />
        <Route path="user-profile" element={<UserProfile />} />
        <Route Route path = "type-prompt"
          element = {
            <TypePrompt activeTab = {
              activeTabTypeAPrompt
            }
            setActiveTab = {
              setActiveTabTypeAPrompt
            }
            generatedImage = {
              generatedImageTypeAPrompt
            }
            setGeneratedImage = {
              setGeneratedImageTypeAPrompt
            }
            showResult = {
              showResultTypeAPrompt
            }
            setShowResult = {
              setShowResultTypeAPrompt
            }
            />} />
      </Route>
{/*
      <Route
        path="*"
        element={
          user ? <> not found</> : <Navigate to="/login" element={<LogIn />} />
        }
      />
*/}
    </Routes>
    </>
  );
};

export default Routers;
