import React from "react";
import { images } from "../../constants";
import "./TypeaPromptNewSearches.scss";
import SizeSelector from "../SizeSelector/SizeSelector";
import RangeSlider from "../RangeSlider/RangeSlider";

const TypeaPromptNewSearches = ({
  generateHandler,
  handlePromptData,
  handleSizeChange,
  promptFormData,
  toggleWizard,
  width,
  height,
  setWidth,
  setHeight
}) => {
  return (
    <div className="tab-content_wrapper">
      <div className="TypePrompt_settings">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-12 col-xl-7">
            <div className="TypePrompt_settings-wrapper">
              <div className="content-title">
                <h3>Settings</h3>
                <span>
                  Type what you'd like to see, and watch it come to life.
                </span>
              </div>
              <form action="" className="mt-5">
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="form-title">
                      <h4>Describe the image you want to see</h4>
                      <span className="sub-title">
                        Include objects, color, locations, people, landscape...
                      </span>
                    </div>
                    <button className="btn btn-wizard" onClick={toggleWizard}>
                      <img src={images.Magic} alt="" className="me-1" />
                      Wizard
                    </button>
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="prompt"
                      value={promptFormData.prompt}
                      onChange={handlePromptData}
                      rows="4"
                      placeholder="Eg. A girl with long blonde hair and big eyes in the grass at sunset"
                    ></textarea>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="form-title">
                      <h4>Size of image</h4>
                      <span className="sub-title">
                        Select the format of the image
                      </span>
                    </div>
                  </div>

                        <SizeSelector handleSizeChange={handleSizeChange} width={width} height={height} setWidth={setWidth} setHeight={setHeight}/>
     
                </div>
                
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="form-title">
                      <h4>Quantity</h4>
                      <span className="sub-title">
                        The number of image to generate
                      </span>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="field d-flex align-items-center justify-content-center">
                      <span className="value left">1</span>
                      <RangeSlider
                        max={4}
                        name="samples"
                        labelSwitch="auto"
                        value={promptFormData.samples}
                        handlePromptData={handlePromptData}
                      />
                      <span className="value right">4</span>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  <button className="btn-generate btn-yellow" onClick={generateHandler}>
                    Generate
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeaPromptNewSearches;
