import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation } from "swiper";

import Information from "../../../assets/Icons/Information.svg";

/**
 *@component Component to show image carousel and also image preview
 *
 * @param  {String} image image url to show preview of dynamic image
 * @return  {Jsx}  It returns jsx for render carousel of images.
 *
 */

function ImageGallery({ image, exampleImages }) {
  return (
    <div className="img_carousel_container">
      
        {exampleImages?(<div className="info_container">
        <img src={Information} alt="" />
        <label className="info_text">
          Reference images which were used to create this category
        </label>      </div>):(null)}

      <img src={image} className="img_preview" />
      {exampleImages?(
      <div className="img_carousel_wrapper">
        <Swiper
          slidesPerView={1}
          grid={{
            fill: "row",
          }}
          spaceBetween={12}
          navigation={true}
          modules={[Grid, Navigation]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {exampleImages.map((item) => {
            return (
              <SwiperSlide>
                <div
                  style={{
                    width: "112px",
                    height: "100px",
                    border: "1px solid #ED5683",
                    filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1))",
                    borderRadius: "4px",
                  }}
                >
                  <img src={item} style={{width: "100%", height: "100%", objectFit: "cover"}} />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>):(null)}
    </div>
  );
}

export default ImageGallery;
