import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import { images } from "../../constants";
import "./UseImage.scss";
import { img2Img } from "../../redux/Thunks/Thunks";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import PendingSearch from "../../components/PendingSearches/PendingSearch";
import PreviousSearch from "../../components/PreviousSearch/PreviousSearch";
import { resetState } from "../../redux/Slice/UseImage";
import UseImageNewSearch from "../../components/UseImageNewSearch/UseImageNewSearch";
import Tabs from "../../components/Tabs/Tabs";
import { db } from "./../../firebase/Setup/Setup";
import GenerateImages from "../../components/GenerateImages/GenerateImages";
import PendingAlertNotification from "../../components/AlertNotification/PendingAlertNotification";
import { toast } from "react-toastify";
import { AuthContext } from "../../firebase/Authentication/context";
import { Navigate } from "react-router-dom";
import LogIn from "../LogIn/LogIn";


const { collection, onSnapshot } = require("firebase/firestore");
/**
 * Page to preview new searches images, pending images and previous images
 * @param  {Number} value having starting number of ai slider
 * @param  {Number}  setValue to set the value of ai slider
 * @param  {Number} quantityValue having starting  number of quantity slider
 * @param  {Number}  setQuantityValue to set the value of quantity slider
 * @component
 * @ImageUploader passing image uploader data to this component.
 * @SwiperSlider show uploaded images.
 * @return  {Jsx}  It returns jsx to render image uploader, sliders and heading.
 *
 * )
 */

const UseImage = ({
    activeTab,
    setActiveTab,
    generatedImage,
    setGeneratedImage,
    showResult,
    setShowResult
  }) => {
  const {user} = useContext(AuthContext);
  const dispatch = useDispatch();
  // const { loading, success, tableData } =
  //   useSelector((state) => state.useImage) || {};
  const {loading, success} = useSelector((state) => state.account);
  const [tableData, setTableData] = useState([])
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [imageGeneratedAlert, setImageGeneratedAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [newSearchValues, setNewSearchValues] = useState({
    size: 512,
    quantity: 1,
    width: 512,
    height: 512,
    prompt_strength:1,
  });

  const [wizardFormData, setWizardFormData] = useState([
    { preset: "", presetNegativePrompt:"", step: 1 },
    { preset: "", presetNegativePrompt:"", step: 2 },
    { preset: "", presetNegativePrompt:"", step: 3 },
    { preset: "", presetNegativePrompt:"", step: 4 },
    { preset: "", presetNegativePrompt:"", step: 5 },
    { preset: "", presetNegativePrompt:"", step: 6 },
    { preset: "", presetNegativePrompt:"", step: 7 },
    { preset: "", presetNegativePrompt:"", step: 8 },
    { preset: "", presetNegativePrompt:"", step: 9 },
    { preset: "", presetNegativePrompt:"", step: 10 },
    ]);


  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    setShowResult(false);
  };
  const generateHandler = (e) => {
    e.preventDefault();
    toast(<PendingAlertNotification />, {
      autoClose: 3000,
      hideProgressBar: true,
      className: "pending-alert",
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    const allWizardPostScripts = wizardFormData
    .map(obj => obj.preset)
    .filter(preset => preset !=='')
    .join(',');

    const allWizardNegativePrompts = wizardFormData
    .map(obj => obj.presetNegativePrompt)
    .filter(presetNegativePrompt => presetNegativePrompt !=='')
    .join(',');
    
    let finalPrompt = newSearchValues.prompt + ", " + allWizardPostScripts

    dispatch(
      img2Img({
        prompt: finalPrompt,
        negative_prompt: allWizardNegativePrompts,
        init_image: newSearchValues.file,
        init_image_name: newSearchValues.file_name,
        samples: newSearchValues.quantity,
        prompt_strength : ((newSearchValues.prompt_strength-1)/10).toFixed(1),
      })
    );
    setWizardFormData([
    { preset: "", presetNegativePrompt:"", step: 1 },
    { preset: "", presetNegativePrompt:"", step: 2 },
    { preset: "", presetNegativePrompt:"", step: 3 },
    { preset: "", presetNegativePrompt:"", step: 4 },
    { preset: "", presetNegativePrompt:"", step: 5 },
    { preset: "", presetNegativePrompt:"", step: 6 },
    { preset: "", presetNegativePrompt:"", step: 7 },
    { preset: "", presetNegativePrompt:"", step: 8 },
    { preset: "", presetNegativePrompt:"", step: 9 },
    { preset: "", presetNegativePrompt:"", step: 10 },
    ])
  };

  useEffect(() => {
    let unsubscribe;
    if(user){
      unsubscribe = onSnapshot(
        collection(db, `Users/${user.uid}/generationsI2I`), 
        (snapshot) => {
          let userDocs = [];
          snapshot.docs.map(doc => {
            userDocs.push(doc.data())
          })
          setTableData(userDocs)
        },
        (error) => {
          toast.error('Please refresh the page and try again!', {
            position: "top-center",
          });
        }
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user])

  useEffect(() => {
    if (loading) {
      setLoadingAlert(true);
      setNewSearchValues({
        prompt_strength: 1,
        quantity: 1,
        width: 512,
        height: 512,
      });
      dispatch(resetState());
    }
    if (success) {
      setLoadingAlert(false);
      setImageGeneratedAlert(true);
      setNewSearchValues({
        prompt_strength: 1,
        quantity: 1,
        width: 512,
        height: 512,
      });
      dispatch(resetState());
    }
  }, [loading, success]);

  const showGenerated = () => {
    setLoadingAlert(false);
  };

  const handleResult = (val) => {
    if(val)
      setShowResult(val);
    else
      setShowResult(!showResult);
  };

  // wizard toggle on off
  const toggleWizard = (e) => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const handleSizeChange = (width, height) => {
    setNewSearchValues((prevData) => {
      return {
        ...prevData,
        width: width,
        height: height,
      };
    });
  };
  
  const newSearchValueChangeHandle = (e) => {
    const { value, name } = e.target || {};
    setNewSearchValues((prevData) => {
      return {
        ...prevData,
        [name]: value
      };
    });
  };

  return (
    <>
        {!user?(null):(

    <>
      <div className="useImage">
        <Tabs
          onClickTabItem={onClickTabItem}
          activeTab={activeTab}
          showDot={
            tableData.some((item) => item.isDone === false)
              ? "pending"
              : tableData.length > 0
              ? "previous"
              : ""
          }
        />
        <div className="tab-content">
          {activeTab === "newSearches" && (
            <UseImageNewSearch
              generateHandler={generateHandler}
              newSearchValueChangeHandle={newSearchValueChangeHandle}
              handleSizeChange={handleSizeChange}
              newSearchValues={newSearchValues}
              wizardFormData = {wizardFormData}
              setWizardFormData={setWizardFormData}
              toggleWizard={toggleWizard}
              isOpen={isOpen}
              setNewSearchValues={setNewSearchValues}
            />
          )}
          {activeTab === "pendingSearches" && (
            <PendingSearch
              tableData={tableData.filter((item) => item.isDone === false)}
              icon={images.ImgGallery}
            />
          )}
          {activeTab === "previousSearches" && (
            <PreviousSearch
              icon={images.ImgGallery}
              showResult={showResult}
              tableData={tableData.filter(
                (item) => item.isDone === true
              )}
              handleResult={handleResult}
              setGeneratedImage={setGeneratedImage}
              generatedImage={generatedImage}
            />
          )}
        </div>
      </div>
      
      {
        ((showResult && activeTab === "previousSearches")) && (
        <GenerateImages
          generatedImage={generatedImage}
          setGeneratedImage={setGeneratedImage}
          handleResult={handleResult}
          onClickTabItem={onClickTabItem}
        />
      )}
    </>
        )}
    </>
  );
};

export default UseImage;
